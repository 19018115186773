import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export function DownloadIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g style={{display:"inline"}} transform="translate(0 12.7)"> 
                <g style={{display:"inline"}} transform="translate(-323.842 -1104.57) scale(.4421)">
                    <g>
                        <polygon className="st1" points="766.7,2503.82 766.95,2509.66 774.67,2509.66 774.67,2502.14" style={{fill:"#2f1c63"}} />
                        <path className="st1" d="m 775.43,2489.03 -8.48,8.48 -8.48,-8.48 c -1.68,-1.68 -4.4,-1.68 -6.08,0 v 0 c -1.68,1.68 -1.68,4.4 0,6.08 l 8.48,8.48 6.08,6.08 6.08,-6.08 8.48,-8.48 c 1.68,-1.68 1.68,-4.4 0,-6.08 v 0 c -1.68,-1.68 -4.4,-1.68 -6.08,0 z" style={{fill:"#2f1c63"}} />
                        <rect x="755.4" y="2487.77" className="st1" width="7.49" height="5.88" style={{fill:"#2f1c63"}} />
                        <rect x="778.48" y="2487.77" className="st1" width="7.67" height="5.88" style={{fill:"#2f1c63"}} />
                        <path className="st2" d="m 783.15,2489.03 -8.48,8.48 -8.48,-8.48 c -1.68,-1.68 -4.4,-1.68 -6.08,0 v 0 c -1.68,1.68 -1.68,4.4 0,6.08 l 8.48,8.48 6.08,6.08 6.08,-6.08 8.48,-8.48 c 1.68,-1.68 1.68,-4.4 0,-6.08 v 0 c -1.68,-1.68 -4.4,-1.68 -6.08,0 z" style={{fill:"#9053ec"}} />
                    </g>
                    <g>
                        <rect x="783.91" y="2509.66" className="st1" width="7.72" height="5.15" style={{fill:"#2f1c63"}} />
                        <rect x="749.98" y="2509.66" className="st1" width="7.72" height="5.15" style={{fill:"#2f1c63"}} />
                        <path className="st1" d="m 788.18,2519.7 v -5.74 c 0,-2.37 -1.92,-4.3 -4.3,-4.3 -2.37,0 -4.3,1.92 -4.3,4.3 v 5.74 h -25.3 v -5.74 c 0,-2.37 -1.92,-4.3 -4.3,-4.3 -2.37,0 -4.3,1.92 -4.3,4.3 v 5.74 4.74 3.86 h 42.54 v -8.6 z" style={{fill:"#2f1c63"}} />
                        <path className="st2" d="m 795.9,2519.7 v -5.74 c 0,-2.37 -1.92,-4.3 -4.3,-4.3 -2.37,0 -4.3,1.92 -4.3,4.3 v 5.74 H 762 v -5.74 c 0,-2.37 -1.92,-4.3 -4.3,-4.3 -2.37,0 -4.3,1.92 -4.3,4.3 v 5.74 4.74 3.86 h 42.54 v -8.6 z" style={{fill:"#9053ec"}} />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}