export const GQL_GET_SELF = `
query getSelf($TOKEN_SUB: String!){
  users (where: {sub: $TOKEN_SUB}) {
    id
    label
    sub
    status
    givenName
    familyName
    createdTimestamp
    lastUpdatedTimestamp
    lastAuthenticatedTimestamp
  }
}
`

export const GQL_GET_USER = `
query sdk_get_user($LABEL: EmailAddress!) {
    users (where: {label: $LABEL, status: "active"}){
      id
      label
      sub
      status
      givenName
      familyName
      createdTimestamp
      lastUpdatedTimestamp
      lastAuthenticatedTimestamp
    }
}`;