import { gql, useApolloClient } from '@apollo/client';
import { Button, TextField } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser, UserState } from '../state/user-slice';

const DEVICE_QUERY = gql`
  query GetDevice {
    device @client {
      robotId
      sessionId
    }
  }
`;

const AccountContent = () => {
  const globalState = useApolloClient();

  const userState: UserState = useSelector(selectUser);

  const [device, setDevice] = useState({ robotId: '', sessionId: '' });
  useEffect(() => {
    globalState.query({ query: DEVICE_QUERY }).then((result) => {
      setDevice(result.data.device);
    });
  });
  const updateGlobalState = (device: any) => {
    globalState.writeQuery({
      query: DEVICE_QUERY,
      data: {
        device: device,
      },
    });
  };
  const handleRobotChange = (event: any) => {
    const update = { ...device, robotId: event.target.value };
    setDevice(update);
    updateGlobalState(update);
  };
  const handleSessionChange = (event: any) => {
    const update = { ...device, sessionId: event.target.value };
    setDevice(update);
    updateGlobalState(update);
  };

  const login = () => {
    if (typeof window !== `undefined`) {
      navigate('/login', { state: { prevPath: window.location.pathname } });
    }
  };

  const logout = () => {
    if (typeof window !== `undefined`) {
      navigate('/logout', { state: { prevPath: window.location.pathname } });
    }
  };

  return (
    <>
      <Card>
        <CardHeader title="Account Details" />
        <CardContent>
          <List>
            {(userState.user?.label == "guest@navability.io" ?? true) && (
              <ListItem>
                <Button variant="contained" color="secondary" onClick={login}>
                  Login
                </Button>
              </ListItem>
            )}
            {userState.user?.label != "guest@navability.io" && (
              <>
                <ListItem>
                  <Button variant="contained" color="secondary" onClick={logout}>
                    Logout
                  </Button>
                </ListItem>
              </>
            )}
            <ListItem>
              <ListItemText primary="UserId" secondary={userState.user.label} />
            </ListItem>
            {userState.user?.label != "guest@navability.io" && (
              <>
                <ListItem>
                  <ListItemText primary="Email" secondary={userState.user.label} />
                </ListItem>
              </>
            )}
          </List>
        </CardContent>
      </Card>
      <Card>
        <CardHeader title="This Device" />
        <CardContent>
          <List>
            <ListItem>
              <TextField label="RobotId" value={device.robotId} onChange={handleRobotChange} />
            </ListItem>
            <ListItem>
              <TextField label="SessionId" value={device.sessionId} onChange={handleSessionChange} />
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </>
  );
};

export default AccountContent;
