import { createSlice, PayloadAction } from '@reduxjs/toolkit'; //eslint-disable-line sort-imports
import { RootState } from '../../app/store';
import { logOut } from '../../services/token-storage';
import { GUEST_USER, User } from '../../types/base';

export interface UserState {
  user: User;
  status: 'idle' | 'loading' | 'error';
  error: string | undefined;
}

const initialState: UserState = {
  user: GUEST_USER,
  status: 'idle',
  error: undefined,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setStatus: (state, action: PayloadAction<{ status: 'idle' | 'loading' | 'error'; error: undefined | string }>) => {
      state.status = action.payload.status;
      state.error = action.payload.error;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = GUEST_USER;
      //Double down and make sure the token is cleared.
      logOut();
    },
  },
});

export const { setStatus, setUser, logout } = userSlice.actions;

//Selectors
export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
