import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export function SolveThisIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g style={{ display: "inline", }} transform="translate(0 12.7)" >
                <g style={{ display: "inline", }} transform="matrix(.34436 0 0 .34436 -1031.87 -708.88)" >
                    <g>
                        <path className="st1" d="m 3051.98,2065.13 h -21.46 c -3.75,0 -6.78,-3.04 -6.78,-6.78 v -21.46 c 0,-3.75 3.04,-6.78 6.78,-6.78 h 21.46 c 3.75,0 6.78,3.04 6.78,6.78 v 21.46 c 0,3.75 -3.03,6.78 -6.78,6.78 z" style={{ fill: "#2f1c63", }} />
                        <path className="st1" d="m 3023.74,2053.78 v 0 c -3.4,0 -6.16,-2.76 -6.16,-6.16 v 0 c 0,-3.4 2.76,-6.16 6.16,-6.16 v 0 z" style={{ fill: "#2f1c63", }} />
                        <path className="st1" d="m 3058.76,2053.78 v 0 -12.33 0 c 3.4,0 6.16,2.76 6.16,6.16 v 0 c 0.01,3.41 -2.75,6.17 -6.16,6.17 z" style={{ fill: "#2f1c63", }} />
                        <path className="st2" d="m 3060.74,2065.13 h -21.46 c -3.75,0 -6.78,-3.04 -6.78,-6.78 v -21.46 c 0,-3.75 3.04,-6.78 6.78,-6.78 h 21.46 c 3.75,0 6.78,3.04 6.78,6.78 v 21.46 c 0,3.75 -3.03,6.78 -6.78,6.78 z" style={{ fill: "#9053ec", }} />
                        <path className="st2" d="m 3032.5,2053.78 v 0 c -3.4,0 -6.16,-2.76 -6.16,-6.16 v 0 c 0,-3.4 2.76,-6.16 6.16,-6.16 v 0 z" style={{ fill: "#9053ec", }} />
                        <path className="st2" d="m 3067.52,2053.78 v 0 -12.33 0 c 3.4,0 6.16,2.76 6.16,6.16 v 0 c 0.01,3.41 -2.75,6.17 -6.16,6.17 z" style={{ fill: "#9053ec", }} />
                        <path className="st1" d="m 3050.01,2060.75 c 6.04,0 10.93,-4.89 10.93,-10.93 h -21.86 c 0,6.04 4.89,10.93 10.93,10.93 z" style={{ fill: "#2f1c63", }} />
                        <path className="st1" d="m 3045.43,2045.29 c 0.51,-0.62 0.82,-1.4 0.82,-2.26 0,-1.98 -1.61,-3.59 -3.59,-3.59 -1.98,0 -3.59,1.61 -3.59,3.59 0,0.86 0.32,1.64 0.82,2.26 z" style={{ fill: "#2f1c63", }} />
                        <path className="st1" d="m 3060.12,2045.29 c 0.51,-0.62 0.82,-1.4 0.82,-2.26 0,-1.98 -1.61,-3.59 -3.59,-3.59 -1.98,0 -3.59,1.61 -3.59,3.59 0,0.86 0.32,1.64 0.82,2.26 z" style={{ fill: "#2f1c63", }} />
                    </g>
                    <line className="st20" x1={3059.66} y1={2101.06} x2={3059.66} y2={2076.23} style={{ fill: "none", stroke: "#9053ec", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    <line className="st20" x1={3073.69} y1={2088.64} x2={3073.69} y2={2063.81} style={{ fill: "none", stroke: "#9053ec", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    <line className="st20" x1={3017.58} y1={2088.64} x2={3017.58} y2={2063.81} style={{ fill: "none", stroke: "#9053ec", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    <line className="st20" x1={3031.6} y1={2101.06} x2={3031.6} y2={2076.23} style={{ fill: "none", stroke: "#9053ec", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    <line className="st20" x1={3045.63} y1={2106.66} x2={3045.63} y2={2081.82} style={{ fill: "none", stroke: "#9053ec", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    <circle className="st2" cx={3045.63} cy={2106.66} r={4.87} style={{ fill: "#9053ec", }} />
                    <circle className="st2" cx={3059.66} cy={2101.06} r={4.87} style={{ fill: "#9053ec", }} />
                    <circle className="st2" cx={3073.69} cy={2088.64} r={4.87} style={{ fill: "#9053ec", }} />
                    <circle className="st2" cx={3031.6} cy={2101.06} r={4.87} style={{ fill: "#9053ec", }} />
                    <circle className="st2" cx={3017.58} cy={2088.64} r={4.87} style={{ fill: "#9053ec", }} />
                </g>
            </g>
        </SvgIcon>
    );
}