export const GQL_GET_ROBOTS = `
query sdk_get_robots($USER_LABEL: EmailAddress!) {
    users (where: {label: $USER_LABEL}){
      robots {
        id
        label
        metadata
        createdTimestamp
        lastUpdatedTimestamp
        _version
      }
    }
}`;

export const GQL_GET_ROBOTS_SUMMARY = `
query sdk_get_robots($USER_LABEL: EmailAddress!) {
    users (where: {label: $USER_LABEL}){
      robots {
        id
        label
      }
    }
}`;

export const GQL_ADD_ROBOT = `
mutation ($USER_LABEL: EmailAdress!, $UNIQUE_KEY: String!, $LABEL: String!, $METADATA: String!) {
  createRobots(
    input: {
      uniqueKey: $UNIQUE_KEY,
    	label: $LABEL,
      metadata: $METADATA,
      _version: "0.20.0",
      user: {
        connect: {
          where:{
            node: {
              label: $USER_LABEL
            }
          },
        }
      }
    }) {
    robots {
      id
    }
  }
}`