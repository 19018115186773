import { createSlice, PayloadAction } from '@reduxjs/toolkit'; //eslint-disable-line sort-imports
import { Factor, Variable } from 'navabilitysdk';
import { RootState } from '../../app/store';
import { Robot, Session, User } from '../../types/base';

export interface FactorGraphVizState {
  user: User | null;
  loadedSession: { session: Session; robot: Robot; variables: Variable[]; factors: Factor[] } | null;
  selectedNode: Variable | Factor | null;
  statusLeftPane: 'idle' | 'loading' | 'error';
  statusViz: 'idle' | 'loading' | 'error';
  statusRightPane: 'idle' | 'loading' | 'error';
  errorLeftPane: string | null;
  errorViz: string | null;
  errorRightPane: string | null;
}

const initialState: FactorGraphVizState = {
  user: null,
  loadedSession: null,
  selectedNode: null,
  statusLeftPane: 'idle',
  statusViz: 'idle',
  statusRightPane: 'idle',
  errorLeftPane: null,
  errorViz: null,
  errorRightPane: null,
};

export const factorgraphVizSlice = createSlice({
  name: 'factorgraph',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setStatusLeftPane: (
      state,
      action: PayloadAction<{ status: 'idle' | 'loading' | 'error'; error: null | string }>,
    ) => {
      state.statusLeftPane = action.payload.status;
      state.errorLeftPane = action.payload.error;
    },
    setStatusViz: (state, action: PayloadAction<{ status: 'idle' | 'loading' | 'error'; error: null | string }>) => {
      state.statusViz = action.payload.status;
      state.errorViz = action.payload.error;
    },
    setStatusRightPane: (state, action: PayloadAction<{ status: 'idle' | 'loading' | 'error'; error: null | string }>) => {
      state.statusRightPane = action.payload.status;
      state.errorRightPane = action.payload.error;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    setLoadedSession: (
      state,
      action: PayloadAction<{ session: Session; robot: Robot; variables: Variable[]; factors: Factor[] } | null>,
    ) => {
      state.loadedSession = action.payload;
      state.selectedNode = null;
    },
    setSelectedNode: (
      state,
      action: PayloadAction<Variable | Factor | null>
    ) => {
      state.selectedNode = action.payload;
    }
  },
});

export const { setStatusLeftPane, setStatusViz, setStatusRightPane, setUser, setLoadedSession, setSelectedNode } = factorgraphVizSlice.actions;

//Selectors
export const selectFactorGraphView = (state: RootState) => state.factorgraphViz;

export default factorgraphVizSlice.reducer;
