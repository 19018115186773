export const GQL_GET_ROBOTS_LATEST = `
query sdk_get_robots_latest($USERNAME: EmailAddress!)
{
    users (where: {label: $USERNAME}) {
      robots {
        id
        label
        lastUpdatedTimestamp
        createdTimestamp
        sessions (options: {sort: {lastUpdatedTimestamp: DESC}, limit: 1}) {
          id
          label
          createdTimestamp
          lastUpdatedTimestamp
        }
      }
    }
  }
`