import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export function ForwardIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g style={{ display: "inline", }} transform="translate(0 12.7)" >
                <g style={{ display: "inline", }} transform="translate(-531.722 -1517.87) scale(.4421)" >
                    <path className="st1" d="m 1251.3,3442.89 -8.82,-8.82 -5.51,-5.51 v -8.61 h -11.2 c -1.6,0 -3.19,0.61 -4.41,1.83 -2.43,2.43 -2.43,6.38 0,8.82 l 12.29,12.29 -12.29,12.29 c -2.43,2.43 -2.43,6.38 0,8.82 1.22,1.22 2.81,1.83 4.41,1.83 h 11.2 v -8.61 l 5.51,-5.51 z" style={{ fill: "#2f1c63", }} />
                    <path className="st2" d="m 1232.57,3430.59 12.29,12.29 -12.29,12.29 c -2.43,2.43 -2.43,6.38 0,8.82 v 0 c 2.43,2.43 6.38,2.43 8.82,0 l 12.29,-12.29 8.82,-8.82 -8.82,-8.82 -12.29,-12.29 c -2.43,-2.43 -6.38,-2.43 -8.82,0 v 0 c -2.43,2.44 -2.43,6.39 0,8.82 z" style={{ fill: "#9053ec", }} />
                </g>
            </g>
        </SvgIcon>
    );
}