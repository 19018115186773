import { gql } from '@apollo/client'

export const SCHEMA_VERSION = "2022_05_22"

export const INITIAL_STATE_QUERY = gql`
    query {
        pages {
            getStarted {
                introduction {
                    step
                    context
                    firstRobotId
                    firstSessionId
                    secondRobotId
                    secondSessionId
                }
            }
            geometricMap {
                showDistribution
                showClown
                showDyads
            }
        }
        account {
            userId
            name
            email
            phone
            isAuthenticated
        }
        device {
            robotId
            sessionId
        }
        filters {
            twin {
                userId
                robotId
                sessionId
                variableLabel
                requestId
            }
            swarm {
                environmentIds
                userIds
                robotIds
                sessionIds
            }
        }
    }`;

export const INITIAL_STATE = {
    pages: {__typename: 'pages',
        getStarted: {__typename: 'getStarted',
            introduction: {__typename: 'introduction',
                step: 0,
                context: 'SIMULATE',
                firstRobotId: 'MyFirstIntroBot',
                firstSessionId: 'IntroductionSession',
                secondRobotId: 'MySecondIntroBot',
                secondSessionId: 'IntroductionSession'
            }
        },
        geometricMap: {__typename: 'geometricMap',
            showDistribution: false,
            showClown: false,
            showDyads: false
        }
    },
    account: {__typename: 'account',
        userId: 'guest@navability.io',
        name: 'Guest',
        email: 'guest@navability.io',
        phone: '',
        isAuthenticated: false
    },
    device: {__typename: 'device',
        robotId: 'AppDevice',
        sessionId: 'AppSession'
    },
    filters: {__typename: 'filters',
        twin: {__typename: 'twin',
            userId: 'guest@navability.io',
            robotId: 'AppDevice',
            sessionId: 'AppSession',
            variableLabel: '',
            requestId: ''
        },
        swarm: {__typename: 'swarm',
            environmentIds: [],
            userIds: [],
            robotIds: [],
            sessionIds: []
        }
    }
}