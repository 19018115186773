import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export function SpatialVisualizationIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g style={{ display: "inline", }} transform="translate(0 12.7)" >
                <g style={{ display: "inline", }} transform="translate(-379.335 -1477.44) scale(.4421)" >
                    <g>
                        <rect x={908.34} y={3351.03} className="st1" width={4.99} height={4.99} style={{ fill: "#2f1c63", }} />
                        <rect x={879.77} y={3351.03} className="st1" width={4.55} height={4.03} style={{ fill: "#2f1c63", }} />
                        <polygon className="st1" points="924.79,3358 924.79,3368.73 908.34,3361.1 918.07,3354.7" style={{ fill: "#2f1c63", }} />
                        <polygon className="st1" points="867.87,3358 867.87,3368.73 884.32,3361.1 874.58,3354.7" style={{ fill: "#2f1c63", }} />
                        <g>
                            <polygon className="st1" points="920.86,3366.92 881.05,3385.39 871.8,3381.21 871.8,3371.13 881.05,3375.32 920.86,3356.84" style={{ fill: "#2f1c63", }} />
                            <g>
                                <polygon className="st1" points="871.8,3366.92 911.6,3385.39 920.86,3381.21 920.86,3371.13 911.6,3375.32 871.8,3356.84" style={{ fill: "#2f1c63", }} />
                                <polygon className="st2" points="880.39,3352.85 871.8,3356.84 911.6,3375.32 920.86,3371.13" style={{ fill: "#9053ec", }} />
                            </g>
                            <polygon className="st2" points="912.26,3352.85 920.86,3356.84 881.05,3375.32 871.8,3371.13" style={{ fill: "#9053ec", }} />
                        </g>
                        <g>
                            <g>
                                <polygon className="st2" points="924.79,3369.29 908.01,3376.88 924.79,3376.88" style={{ fill: "#9053ec", }} />
                                <rect x={908.01} y={3376.88} className="st1" width={16.78} height={10.07} style={{ fill: "#2f1c63", }} />
                            </g>
                            <g>
                                <polygon className="st2" points="867.87,3369.29 884.65,3376.88 867.87,3376.88" style={{ fill: "#9053ec", }} />
                                <rect x={867.87} y={3376.88} className="st1" width={16.78} height={10.07} style={{ fill: "#2f1c63", }} />
                            </g>
                        </g>
                        <polygon className="st2" points="867.87,3358.66 884.32,3351.03 867.87,3351.03" style={{ fill: "#9053ec", }} />
                        <polygon className="st2" points="924.79,3358.66 908.34,3351.03 924.79,3351.03" style={{ fill: "#9053ec", }} />
                    </g>
                    <g>
                        <rect x={892.43} y={3315.93} className="st1" width={7.8} height={29.58} style={{ fill: "#2f1c63", }} />
                        <path className="st1" d="m 924.79,3330.72 c 0,0 -13.15,-14.79 -24.56,-14.79 -11.41,0 -24.56,14.79 -24.56,14.79 0,0 13.15,14.79 24.56,14.79 11.41,0 24.56,-14.79 24.56,-14.79 z" style={{ fill: "#2f1c63", }} />
                        <path className="st2" d="m 916.99,3330.72 c 0,0 -13.15,-14.79 -24.56,-14.79 -11.41,0 -24.56,14.79 -24.56,14.79 0,0 13.15,14.79 24.56,14.79 11.41,0 24.56,-14.79 24.56,-14.79 z" style={{ fill: "#9053ec", }} />
                        <circle className="st1" cx={892.43} cy={3330.72} r={10.68} style={{ fill: "#2f1c63", }} />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}