import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { Map as NavAbilityMap} from '../../types/base' // To avoid collision with the datastructure Map

export interface MapperState {
  maps: NavAbilityMap[];
  status: 'loading' | 'idle' | 'error';
  error: string | undefined;
  selectedMap: NavAbilityMap | undefined;
}

const initialState: MapperState = {
  maps: [],
  status: 'idle',
  error: undefined,
  selectedMap: undefined,
}

export const mapsSlice = createSlice({
  name: 'maps',
  initialState,
  reducers: {
    setStatus: (state: MapperState, action: PayloadAction<{status: 'loading' | 'idle' | 'error', error: string | undefined}>) => {
      state.status = action.payload.status;
      state.error = action.payload.error;
    },
    setMaps: (state: MapperState, action: PayloadAction<NavAbilityMap[]>) => {
      state.maps = action.payload;
      //Reset the maps
      state.selectedMap = undefined; 
    },
    setSelectedMap: (state: MapperState, action: PayloadAction<NavAbilityMap|undefined>) => {
      state.selectedMap = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setStatus, setMaps, setSelectedMap } = mapsSlice.actions

// Selectors
export const selectMaps = (state: RootState) => state.maps;

export default mapsSlice.reducer