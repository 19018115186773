import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export function SDKIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g style={{ display: "inline", }} transform="translate(0 12.7)" >
                <g style={{ display: "inline", }} transform="matrix(.41621 0 0 .41621 -1226.583 -332.543)" >
                    <rect x={2953.54} y={780.59} className="st1" width={57.11} height={57.11} style={{ fill: "#2f1c63", }} />
                    <rect x={2964.74} y={780.59} className="st2" width={57.11} height={57.11} style={{ fill: "#9053ec", }} />
                    <path className="st1" d="m 2999.94,810.81 -7.45,-7.45 c 0.94,-3.04 0.21,-6.49 -2.2,-8.9 -2.41,-2.41 -5.86,-3.14 -8.9,-2.2 l 5.55,5.55 -5.85,5.85 v 0 l -5.55,-5.55 c -0.94,3.04 -0.21,6.49 2.2,8.9 2.41,2.41 5.86,3.14 8.9,2.2 l 7.45,7.45 c -0.94,3.04 -0.21,6.49 2.2,8.9 3.47,3.47 9.09,3.47 12.55,0 3.47,-3.47 3.47,-9.09 0,-12.55 -2.4,-2.41 -5.85,-3.14 -8.9,-2.2 z m 6.63,9.55 -2.93,2.93 -4,-1.07 -1.07,-4 2.93,-2.93 4,1.07 z" style={{ fill: "#2f1c63", }} />
                </g>
            </g>
        </SvgIcon >
    );
}