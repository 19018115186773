export const GQL_GET_SESSIONS = `
query sdk_get_sessions($USER_LABEL: EmailAddress!, $ROBOT_LABEL: String!) {
    users (where: {label: $USER_LABEL}){
      robots (where: {label: $ROBOT_LABEL}) {
        sessions {
          id
          label
          metadata
          createdTimestamp
          lastUpdatedTimestamp
          userLabel
          robotLabel
          originLatitude
          originLongitude
          _version
        }
      }
    }
}`;

export const GQL_GET_SESSIONS_SUMMARY = `
query sdk_get_sessions($USER_LABEL: String!, $ROBOT_LABEL: String!) {
    users (where: {name: $USER_LABEL}){
      robots (where: {name: $ROBOT_LABEL}) {
        sessions {
          id
          label
        }
      }
    }
}`;