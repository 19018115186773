import { call, put } from 'redux-saga/effects';
import { setMaps, setStatus } from '../../global/state/maps-slice';
import { addMapApi, deleteMapApi, getMapsApi } from '../../services/mapper/maps-api';
import * as actions from '../actions';

/**
 * Get the current map information.
 *
 * @param {ReturnType<typeof actions.getMaps>} action
 * @return {*}
 */
export function* getMaps(action: ReturnType<typeof actions.getMaps>) {
  try {
    yield put(setStatus({ status: 'loading', error: undefined }));

    const maps = yield call(getMapsApi, action.payload.apolloClient, action.payload.user);

    // Set the maps in the slice.
    yield put(setMaps(maps));

    yield put(setStatus({ status: 'idle', error: undefined }));
  } catch (e) {
    console.log(e);
    yield put(setStatus({ status: 'error', error: String(e) }));
    return;
  }
}

/**
 * Add a map.
 *
 * @param {ReturnType<typeof actions.addMap>} action
 * @return {*}
 */
export function* addMap(action: ReturnType<typeof actions.addMap>) {
  try {
    yield put(setStatus({ status: 'loading', error: undefined }));

    const newMapId = yield call(
      addMapApi,
      action.payload.apolloClient,
      action.payload.user,
      action.payload.label,
      action.payload.description,
      action.payload.data,
    );

    yield put(setStatus({ status: 'idle', error: undefined }));

    // Reget all the maps.
    yield put(actions.getMaps({ apolloClient: action.payload.apolloClient, user: action.payload.user }));
  } catch (e) {
    console.log(e);
    yield put(setStatus({ status: 'error', error: String(e) }));
    return;
  }
}

/**
 * Delete a map.
 *
 * @param {ReturnType<typeof actions.addMap>} action
 * @return {*}
 */
export function* deleteMap(action: ReturnType<typeof actions.deleteMap>) {
  try {
    yield put(setStatus({ status: 'loading', error: undefined }));

    yield call(deleteMapApi, action.payload.apolloClient, action.payload.user, action.payload.mapId);

    yield put(setStatus({ status: 'idle', error: undefined }));

    // Reget all the maps.
    yield put(actions.getMaps({ apolloClient: action.payload.apolloClient, user: action.payload.user }));
  } catch (e) {
    console.log(e);
    yield put(setStatus({ status: 'error', error: String(e) }));
    return;
  }
}
