import { call, put } from 'redux-saga/effects';
import { setSessions, setStatus } from '../../components/robots/RobotsSlice';
import { getSessionsApi } from '../../services/mapper/sessions-api';
import * as actions from '../actions';

export function* addSession(action: ReturnType<typeof actions.addSession>) {
}

/**
 * Get all robots for the provided user.
 *
 * @param {ReturnType<typeof actions.login>} action
 * @return {*}
 */
export function* getSessions(action: ReturnType<typeof actions.getSessions>) {
  // Get the user info
  try {
    yield put(setStatus({ status: 'loading', error: undefined }));

    const sessions = yield call(
      getSessionsApi, 
      action.payload.apolloClient, 
      action.payload.userLabel,
      action.payload.robotLabel);
    
    // Set the robots in the slice.
    yield put(setSessions(sessions));

    yield put(setStatus({ status: 'idle', error: undefined }));

  } catch (e) {
    console.log(e);
    yield put(setStatus({ status: 'error', error: String(e) }));
    return;
  }
}

export function* updateSession(action: ReturnType<typeof actions.updateSession>) {
}

export function* deleteSession(action: ReturnType<typeof actions.deleteSession>) {
}
