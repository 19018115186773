import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export function BackwardIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g style={{ display: "inline", }} transform="translate(0 12.7)" >
                <g style={{ display: "inline", }} transform="translate(-429.025 -1517.87) scale(.4421)" >
                    <path className="st1" d="m 1017.18,3430.59 -12.29,12.29 12.29,12.29 c 2.43,2.43 2.43,6.38 0,8.82 v 0 c -2.43,2.43 -6.38,2.43 -8.82,0 l -12.29,-12.29 -8.82,-8.82 8.82,-8.82 12.29,-12.29 c 2.43,-2.43 6.38,-2.43 8.82,0 v 0 c 2.43,2.44 2.43,6.39 0,8.82 z" style={{ fill: "#2f1c63", }} />
                    <rect x={1012.77} y={3419.95} className="st1" width={11.54} height={7.48} style={{ fill: "#2f1c63", }} />
                    <rect x={1012.77} y={3459.19} className="st1" width={11.54} height={6.64} style={{ fill: "#2f1c63", }} />
                    <path className="st2" d="m 1028.38,3430.59 -12.29,12.29 12.29,12.29 c 2.43,2.43 2.43,6.38 0,8.82 v 0 c -2.43,2.43 -6.38,2.43 -8.82,0 l -12.29,-12.29 -8.82,-8.82 8.82,-8.82 12.29,-12.29 c 2.43,-2.43 6.38,-2.43 8.82,0 v 0 c 2.43,2.44 2.43,6.39 0,8.82 z" style={{ fill: "#9053ec", }} />
                </g>
            </g>
        </SvgIcon>
    );
}