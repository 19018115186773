import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export function TutorialIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g style={{ display: "inline", }} transform="translate(0 12.7)" >
                <g style={{ display: "inline", }} transform="translate(-300.36 -1263.477) scale(.4421)" >
                    <g>
                        <rect x={694.02} y={2862.17} className="st1" width={47.34} height={15.79} style={{ fill: "#2f1c63", }} />
                        <ellipse className="st1" cx={717.69} cy={2877.95} rx={23.67} ry={12.08} style={{ fill: "#2f1c63", }} />
                    </g>
                    <polygon className="st1" points="717.69,2878.78 750.3,2864.04 717.69,2849.3 685.09,2864.04" style={{ fill: "#2f1c63", }} />
                    <rect x={685.09} y={2859.65} className="st1" width={65.2} height={4.39} style={{ fill: "#2f1c63", }} />
                    <polygon className="st2" points="717.69,2874.39 750.3,2859.65 717.69,2844.91 685.09,2859.65" style={{ fill: "#9053ec", }} />
                    <polyline className="st23" points="717.69,2859.65 733.93,2867.05 733.93,2878.78" style={{ fill: "none", stroke: "#9053ec", strokeWidth: 2.19440007, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    <circle className="st2" cx={733.93} cy={2879.95} r={3.18} style={{ fill: "#9053ec", }} />
                </g>
            </g>
        </SvgIcon>
    )
}

export function DarkNegativeTutorialIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g style={{ display: "inline", }} transform="translate(0 12.7)" >
                <g style={{ display: "inline", }} transform="translate(-300.36 -1263.477) scale(.4421)" >
                    <g>
                        <rect x={694.02} y={2862.17} className="st1" width={47.34} height={15.79} style={{ fill: "#2f1c63", }} />
                        <ellipse className="st1" cx={717.69} cy={2877.95} rx={23.67} ry={12.08} style={{ fill: "#2f1c63", }} />
                    </g>
                    <polygon className="st1" points="717.69,2878.78 750.3,2864.04 717.69,2849.3 685.09,2864.04" style={{ fill: "#2f1c63", }} />
                    <rect x={685.09} y={2859.65} className="st1" width={65.2} height={4.39} style={{ fill: "#2f1c63", }} />
                    <polygon className="st2" points="717.69,2874.39 750.3,2859.65 717.69,2844.91 685.09,2859.65" style={{ fill: "#ffffff", }} />
                    <polyline className="st23" points="717.69,2859.65 733.93,2867.05 733.93,2878.78" style={{ fill: "none", stroke: "#ffffff", strokeWidth: 2.19440007, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    <circle className="st2" cx={733.93} cy={2879.95} r={3.18} style={{ fill: "#ffffff", }} />
                </g>
            </g>
        </SvgIcon>
    )
}