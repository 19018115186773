import { gql } from '@apollo/client';

import { NavAbilityClient } from 'navabilitysdk';
import { Map, MapData, MapUser } from '../../types/base';
import { GQL_ADD_MAP, GQL_DELETE_MAP, GQL_GET_MAPS } from './graphql/Maps';

/**
 * Get maps for a user.
 *
 * @export
 * @param {NavAbilityClient} navAbilityClient
 * @param {string} sub The sub of the user.
 * @return {*}  {Promise<Map>}
 */
export async function getMapsApi(navAbilityClient: NavAbilityClient, user: MapUser): Promise<Map[]> {
  const response = await navAbilityClient.query({
    query: gql(`${GQL_GET_MAPS}`),
    fetchPolicy: 'network-only',
    variables: {
      USER_ID: user.id,
    },
  });
  if (response.data.errors) {
    throw Error(`Error: ${response.data.errors[0]}`);
  } else {
    const maps = response.data.maps.map((m) => {
      return {
        ...m,
        userRoles:  m.usersConnection.edges.map(u => {return {role: u.role, user: {...u.node}}})
      };
    });
    return maps;
  }
}

/**
 * Create a map for a user and return the new ID.
 *
 * @export
 * @param {NavAbilityClient} navAbilityClient
 * @param {string} sub The sub of the user.
 * @return {*}  {Promise<Map>}
 */
 export async function addMapApi(navAbilityClient: NavAbilityClient, user: MapUser, label: string, description: string, data: MapData): Promise<string> {
  const response = await navAbilityClient.mutate({
    mutation: gql(`${GQL_ADD_MAP}`),
    fetchPolicy: 'network-only',
    variables: {
      USER_ID: user.id,
      LABEL: label,
      DESCRIPTION: description,
      DATA: btoa(JSON.stringify(data))
    },
  });
  if (response.data.errors) {
    throw Error(`Error: ${response.data.errors[0]}`);
  } else {
    console.log(response.data);
    // Return the map ID.
    const map = response.data.addMaps.maps[0];
    return map.id;
  }
}

/**
 * Delete a map, returns nothing.
 *
 * @export
 * @param {NavAbilityClient} navAbilityClient
 * @param {string} user The user (not used at present).
 * @param {string} mapId The ID of the map.
 * @return {*}  {Promise<Map>}
 */
 export async function deleteMapApi(navAbilityClient: NavAbilityClient, user: MapUser, mapId: string) {
  const response = await navAbilityClient.mutate({
    mutation: gql(`${GQL_DELETE_MAP}`),
    fetchPolicy: 'network-only',
    variables: {
      MAP_ID: mapId,
    },
  });
  if (response.data.errors) {
    throw Error(`Error: ${response.data.errors[0]}`);
  } else {
    console.log(response.data);
    // Return nothing.
    return null;
  }
}
