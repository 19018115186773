import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './src/app/store';
import Layout from './src/global/layout/layout';
import { client } from './src/global/state/client';
import NavAbilityUserProvider from './src/global/state/NavAbilityUserProvider';

export const wrapRootElement = ({ element }) => {
  // If building with Gatsby, don't instantiate the ApolloProvider
  // if (process.browser)
  // return (<ReduxProvider store={store}>{element}</ReduxProvider>);
  // Else go on as we should.
  return (
    <ApolloProvider client={client()}>
      <ReduxProvider store={store}>
        <NavAbilityUserProvider>{element}</NavAbilityUserProvider>
      </ReduxProvider>
    </ApolloProvider>
  );
};

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};
