import { call, put } from 'redux-saga/effects';
import { setStatus, setUser } from '../../global/state/user-slice';
import { getSelfApi, getUserApi } from '../../services/mapper/user-api';
import * as actions from '../actions';

/**
 * Get the current user's MapUser.
 *
 * @param {ReturnType<typeof actions.getSelf>} action
 * @return {*}
 */
export function* getSelf(action: ReturnType<typeof actions.getSelf>) {
  // Get the user info
  try {
    yield put(setStatus({ status: 'loading', error: undefined }));

    // Get the user (or guest user if no token is provided)
    var user = yield call(getSelfApi, action.payload.apolloClient, action.payload.userToken);

    // Set the user in the slice.
    yield put(setUser(user));

    yield put(setStatus({ status: 'idle', error: undefined }));

    // Update the maps while we have them to save a round trip.
    // if (user != undefined) yield put(actions.getMaps({ apolloClient: action.payload.apolloClient, user: user }));
  } catch (e) {
    console.log(e);
    yield put(setStatus({ status: 'error', error: String(e) }));
    return;
  }
}

/**
 * Get a user's information information.
 *
 * @param {ReturnType<typeof actions.login>} action
 * @return {*}
 */
export function* getUser(action: ReturnType<typeof actions.getUser>) {
  // Get the user info
  try {
    yield put(setStatus({ status: 'loading', error: undefined }));

    //TODO: Refactor this, it should not update the local user.
    console.log('In the Saga');
    const user = yield call(getUserApi, action.payload.apolloClient, action.payload.label);

    // Set the user in the slice.
    yield put(setUser(user));

    yield put(setStatus({ status: 'idle', error: undefined }));

    // Update the maps while we have them to save a round trip.
    if (user != undefined) yield put(actions.getMaps({ apolloClient: action.payload.apolloClient, user: user }));
  } catch (e) {
    console.log(e);
    yield put(setStatus({ status: 'error', error: String(e) }));
    return;
  }
}
