import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export function ConnectIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g style={{ display: "inline", }} transform="translate(0 12.7)" >
                <g transform="translate(4.265 -39.042) scale(1.67093)">
                    <path className="st1" d="M 12.014139,22.715991 H 8.8315946 v 1.367737 h 1.2418464 v 1.241954 H 8.8315946 v 1.330061 h 1.2418464 v 1.241716 H 8.8315946 v 1.183825 h 3.1825444 c 1.75659,0 3.182522,-1.424093 3.182522,-3.182647 0,-1.756701 -1.424078,-3.182646 -3.182522,-3.182646 z" style={{ display: "inline", fill: "#2f1c63", strokeWidth: 0.18787032, }} />
                    <path className="st1" d="M 4.2268979,29.081284 H 1.0443758 c -1.75846576,0 -3.1825142,-1.424093 -3.1825142,-3.182647 v 0 c 0,-1.758553 1.42404844,-3.182646 3.1825142,-3.182646 h 3.1825221 z" style={{ display: "inline", fill: "#2f1c63", strokeWidth: 0.18787032, }} />
                    <path className="st2" d="M 6.3310656,29.081284 H 3.1485223 c -1.7584446,0 -3.18252206,-1.424093 -3.18252206,-3.182647 v 0 c 0,-1.758553 1.42407746,-3.182646 3.18252206,-3.182646 h 3.1825433 z" style={{ display: "inline", fill: "#9053ec", strokeWidth: 0.18787032, }} />
                    <g transform="translate(-132.764 -466.598) scale(.18787)" style={{ display: "inline", }} >
                        <rect x={740.38} y={2611.81} className="st2" width={6.61} height={6.61} style={{ fill: "#9053ec", }} />
                        <rect x={740.38} y={2625.5} className="st2" width={6.61} height={6.61} style={{ fill: "#9053ec", }} />
                    </g>
                    <path className="st2" d="m 14.118285,22.715991 h -3.182522 v 1.367737 h 1.241817 v 1.241954 h -1.241817 v 1.330061 h 1.241817 v 1.241716 h -1.241817 v 1.183825 h 3.182522 c 1.756582,0 3.182514,-1.424093 3.182514,-3.182647 0,-1.756701 -1.425932,-3.182646 -3.182514,-3.182646 z" style={{ display: "inline", fill: "#9053ec", strokeWidth: 0.18787032, }} />
                </g>
            </g>
        </SvgIcon>
    );
}