import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export function CoffeeIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g style={{ display: "inline", }} transform="translate(0 12.7)" >
                <g style={{ display: "inline", }} transform="translate(-1097.058 -248.201) scale(.37233)" >
                    <g>
                        <rect x={2966.34} y={679.2} className="st1" width={9.19} height={3.35} style={{ fill: "#2f1c63", }} />
                        <rect x={2966.34} y={699.81} className="st1" width={8.92} height={4.25} style={{ fill: "#2f1c63", }} />
                        <path className="st1" d="m 3013.72,714.75 h -33.88 c -4.04,0 -7.32,-3.28 -7.32,-7.32 v -36.97 h 48.51 v 36.97 c 0,4.04 -3.27,7.32 -7.31,7.32 z" style={{ fill: "#2f1c63", }} />
                        <path className="st1" d="m 2966.34,679.2 c -6.86,0 -12.43,5.56 -12.43,12.43 0,6.86 5.56,12.43 12.43,12.43 6.86,0 12.43,-5.56 12.43,-12.43 0,-6.87 -5.56,-12.43 -12.43,-12.43 z m 0,18.51 c -2.8,0 -2.75,-2.72 -2.75,-6.08 0,-3.36 -0.04,-6.08 2.75,-6.08 3.36,0 6.08,2.72 6.08,6.08 0.01,3.35 -2.72,6.08 -6.08,6.08 z" style={{ fill: "#2f1c63", }} />
                        <path className="st2" d="m 3022.64,714.75 h -33.88 c -4.04,0 -7.32,-3.28 -7.32,-7.32 v -36.97 h 48.51 v 36.97 c 0.01,4.04 -3.27,7.32 -7.31,7.32 z" style={{ fill: "#9053ec", }} />
                        <path className="st2" d="m 2975.27,679.2 c -6.86,0 -12.43,5.56 -12.43,12.43 0,6.86 5.56,12.43 12.43,12.43 6.87,0 12.43,-5.56 12.43,-12.43 0,-6.87 -5.57,-12.43 -12.43,-12.43 z m 0,18.51 c -3.36,0 -6.08,-2.72 -6.08,-6.08 0,-3.36 2.72,-6.08 6.08,-6.08 3.36,0 6.08,2.72 6.08,6.08 0,3.35 -2.72,6.08 -6.08,6.08 z" style={{ fill: "#9053ec", }} />
                    </g>
                    <path className="st20" d="m 3012.11,662.46 c 5.4,-5.4 5.4,-14.15 0,-19.55" style={{ fill: "none", stroke: "#9053ec", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    <path className="st20" d="m 3000.92,662.46 c 5.4,-5.4 5.4,-14.15 0,-19.55" style={{ fill: "none", stroke: "#9053ec", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    <path className="st20" d="m 2989.72,662.46 c 5.4,-5.4 5.4,-14.15 0,-19.55" style={{ fill: "none", stroke: "#9053ec", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    <rect x={2995.82} y={680.87} className="st1" width={5.1} height={5.1} style={{ fill: "#2f1c63", }} />
                    <rect x={3000.92} y={685.98} className="st1" width={5.1} height={5.1} style={{ fill: "#2f1c63", }} />
                    <rect x={3000.92} y={691.08} className="st1" width={5.1} height={5.1} style={{ fill: "#2f1c63", }} />
                    <rect x={2995.82} y={691.08} className="st1" width={5.1} height={5.1} style={{ fill: "#2f1c63", }} />
                    <rect x={3006.02} y={685.98} className="st1" width={5.1} height={5.1} style={{ fill: "#2f1c63", }} />
                    <rect x={3006.02} y={680.87} className="st1" width={5.1} height={5.1} style={{ fill: "#2f1c63", }} />
                    <rect x={3011.12} y={691.08} className="st1" width={5.1} height={5.1} style={{ fill: "#2f1c63", }} />
                    <rect x={3011.12} y={680.87} className="st1" width={5.1} height={5.1} style={{ fill: "#2f1c63", }} />
                    <rect x={3006.02} y={696.18} className="st1" width={5.1} height={5.1} style={{ fill: "#2f1c63", }} />
                    <rect x={2995.82} y={696.18} className="st1" width={5.1} height={5.1} style={{ fill: "#2f1c63", }} />
                </g>
            </g>
        </SvgIcon>
    );
}