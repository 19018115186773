import { gql } from '@apollo/client';

import { NavAbilityClient } from 'navabilitysdk';
import { Robot } from '../../types/base';
import { GQL_GET_ROBOTS_LATEST } from './graphql/Robots';

/**
 * Get the latest robots and their latest sessions.
 *
 * @export
 * @param {NavAbilityClient} navAbilityClient
 * @param {string} userName The name of the user.
 * @return {*}  {Promise<Robot>}
 */
export async function getRobotsLatestApi(navAbilityClient: NavAbilityClient, userName: string): Promise<Robot[]> {
  const response = await navAbilityClient.query({
    query: gql(`${GQL_GET_ROBOTS_LATEST}`),
    fetchPolicy: 'network-only',
    variables: {
      USERNAME: userName,
    },
  });

  if (response.data.errors) {
    throw Error(`Error: ${response.data.errors[0]}`);
  } else {
    // Get the first user otherwise undefined.
    return response.data.users.length > 0 ? response.data.users[0].robots : undefined;
  }
}
