export const GQL_GET_WORKFLOWS_FOR_USER = `
query sdk_get_workflows_for_user($USER_ID: ID!){
  workflows (where: {mapConnection: {node: {users_SOME: {id: $USER_ID}}}}) {
  id
  label
  description
  status
  data
  result
  _type
  _version
  
  createdTimestamp
  lastUpdatedTimestamp
  }
}`

export const GQL_GET_WORKFLOWS_FOR_WORKFLOW = `
query sdk_get_workflows_for_map($WORKFLOW_ID: ID!){
  workflows (where: {mapConnection: {node: {id: $WORKFLOW_ID}}}) {
  id
  label
  description
  status
  data
  result
  _type
  _version
  
  createdTimestamp
  lastUpdatedTimestamp
  }
}`

export const GQL_ADD_WORKFLOW = `
mutation ($WORKFLOW: WorkflowCreateInput!) {
  addWorkflows(
    input: [$WORKFLOW]) {
    workflows {
      id
    }
  }
}`

export const GQL_DELETE_WORKFLOW = `
mutation ($WORKFLOW_ID: ID!) {
  deleteWorkflows(
    where: {id: $WORKFLOW_ID}
    ) {
    nodesDeleted 
    bookmark
  }
}`

export const GQL_ATTACH_USER_TO_WORKFLOW = `
mutation ($WORKFLOW_ID: ID!, $USER_ID: ID!, $ROLE: String!){
  updateWorkflows(
    where: { id: $WORKFLOW_ID }
    connect: {
      users: {
        where: { node: { id: $USER_ID } }
        edge: { role: $ROLE }
      }
    }
  ) {
    info {
      nodesCreated
    }
  }
}
`