import { AddBox, Home } from '@mui/icons-material';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Link as MuiLink,
  Tooltip,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Link } from 'gatsby';
import React from 'react';
import {
  CameraIcon,
  CoffeeIcon,
  ConnectIcon,
  CreateIcon,
  ExperimentIcon,
  HexagonalIcon,
  HistoryIcon,
  RawDataIcon,
  SDKIcon,
  SolveSwarmIcon,
  SpatialVisualizationIcon,
  TutorialIcon
} from '../../components/icons/icons';

const useStyles = makeStyles({
  list: {
    width: 300,
  },
  title: {
    textDecoration: 'none',
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  item: {
    paddingRight: '50px',
    a: { color: 'inherit' },
  },
  bottomPush: {
    position: 'fixed',
    bottom: 0,
    textAlign: 'center',
    paddingBottom: 10,
  },
});

function MenuSectionHeading(props: { children?: React.ReactNode; label: string }) {
  return (
    <ListSubheader>
      <Box padding={2} style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography>
          <strong>{props.label}</strong>
          {props.children}
        </Typography>
      </Box>
    </ListSubheader>
  );
}

export default function MenuContent(props: { title: string | undefined; onMenuItemClicked: (e) => void }) {
  // Theming
  const classes = useStyles();

  return (
    <>
      <List className={classes.list}>
        {props.title && (
          <ListSubheader>
            <Typography variant="h3" color="inherit">
              <Link to="/" className={classes.title} onClick={(e) => props.onMenuItemClicked(e)}>
                {props.title}
              </Link>
            </Typography>
          </ListSubheader>
        )}
        <ListItemButton
          component={Link}
          to="/home"
          onClick={(e) => props.onMenuItemClicked(e)}
          className={classes.item}
        >
          <ListItemIcon>
            <Home fontSize="large" style={{ color: 'info.main' }} />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
        <Divider />

        <MenuSectionHeading label="Get Started" />
        <ListItemButton
          component={Link}
          onClick={(e) => props.onMenuItemClicked(e)}
          to="/get-started/introduction"
          className={classes.item}
        >
          <ListItemIcon>
            <CoffeeIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary="Introduction" />
        </ListItemButton>
        <ListItemButton
          component={Link}
          onClick={(e) => props.onMenuItemClicked(e)}
          to="/get-started/tutorials"
          className={classes.item}
        >
          <ListItemIcon>
            <TutorialIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary="Tutorials" />
        </ListItemButton>
        <ListItemButton
          component={Link}
          onClick={(e) => props.onMenuItemClicked(e)}
          to="/get-started/experiment"
          className={classes.item}
        >
          <ListItemIcon>
            <ExperimentIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary="Experiment" />
        </ListItemButton>
        <ListItemButton
          component={Link}
          onClick={(e) => props.onMenuItemClicked(e)}
          to="https://www.navability.io"
          className={classes.item}
        >
          <ListItemIcon>
            <HexagonalIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary="About NavAbility" />
        </ListItemButton>

        <Divider />
        <MenuSectionHeading label="NavAbility Mapper" />
        <Tooltip title="Enterprise digital twin maps">
          <ListItemButton
            component={Link}
            onClick={(e) => props.onMenuItemClicked(e)}
            to="/mapper/mapper"
            className={classes.item}
          >
            <ListItemIcon>
              <CreateIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText primary="NavAbility Mapper" />
          </ListItemButton>
        </Tooltip>
        <Tooltip title="Coming soon">
          <ListItemButton component={Link} disabled onClick={(e) => props.onMenuItemClicked(e)} to="/mapper/potreesandbox" className={classes.item}>
            <ListItemIcon>
              <AddBox fontSize="large" />
            </ListItemIcon>
            <ListItemText primary="Visualization Sandbox" />
          </ListItemButton>
        </Tooltip>
        <Tooltip title="Coming soon">
          <ListItem component={Link} disabled onClick={(e) => props.onMenuItemClicked(e)} to="/tools/boxingtool" className={classes.item} button>
            <ListItemIcon>
              <AddBox fontSize="large" />
            </ListItemIcon>
            <ListItemText primary="NavAbility Boxing Tool" />
          </ListItem>
        </Tooltip>

        <Divider />
        <MenuSectionHeading label="Edge" />
        <ListItem
          component={Link}
          onClick={(e) => props.onMenuItemClicked(e)}
          to="/edge/download"
          className={classes.item}
          button
        >
          <ListItemIcon>
            <SDKIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary="Development Kits (SDKs)" />
        </ListItem>
        <ListItem
          component={Link}
          onClick={(e) => props.onMenuItemClicked(e)}
          to="/edge/connect"
          className={classes.item}
          button
        >
          <ListItemIcon>
            <ConnectIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary="Connect" />
        </ListItem>

        <Divider />
        <MenuSectionHeading label="Cloud" />
        <ListItem component={Link} onClick={(e) => props.onMenuItemClicked(e)} to="/cloud/robots" className={classes.item} button>
          <ListItemIcon>
            <SolveSwarmIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary="Robots" />
        </ListItem>
        <ListItem
          component={Link}
          onClick={(e) => props.onMenuItemClicked(e)}
          to="/cloud/datadashboard"
          className={classes.item}
          button
        >
          <ListItemIcon>
            <CameraIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary="Data Dashboard" />
        </ListItem>
        <ListItem
          component={Link}
          onClick={(e) => props.onMenuItemClicked(e)}
          to="/cloud/graph"
          className={classes.item}
          button
        >
          <ListItemIcon>
            <RawDataIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary="Factor Graph" />
        </ListItem>
        <ListItem
          component={Link}
          onClick={(e) => props.onMenuItemClicked(e)}
          to="/cloud/map"
          className={classes.item}
          button
        >
          <ListItemIcon>
            <SpatialVisualizationIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary="Geometric Map" />
        </ListItem>
        <ListItem
          component={Link}
          onClick={(e) => props.onMenuItemClicked(e)}
          to="/cloud/activity"
          className={classes.item}
          button
        >
          <ListItemIcon>
            <HistoryIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary="Activity Log" />
        </ListItem>
        <Divider />
      </List>
      <MuiLink
        className={classes.bottomPush}
        target="_blank"
        color="primary"
        underline="none"
        variant="body2"
        href="https://www.navability.io"
      >
        {' '}
        &nbsp; &nbsp; © NavAbility {new Date().getFullYear()}
      </MuiLink>
    </>
  );
}
