import { gql } from '@apollo/client';

import { NavAbilityClient } from 'navabilitysdk';
import { Session } from '../../types/base';
import { GQL_GET_SESSIONS } from './graphql/Sessions';

/**
 * Get all sessions for a given user and robot, based on label.
 *
 * @export
 * @param {NavAbilityClient} navAbilityClient
 * @param {string} userName The name of the user.
 * @return {*}  {Promise<Session[]>}
 */
export async function getSessionsApi(
  navAbilityClient: NavAbilityClient,
  userLabel: string,
  robotLabel: string,
): Promise<Session[]> {
  const response = await navAbilityClient.query({
    query: gql(`${GQL_GET_SESSIONS}`),
    fetchPolicy: 'network-only',
    variables: {
      USER_LABEL: userLabel,
      ROBOT_LABEL: robotLabel,
    },
  });
  if (response.data.errors) {
    throw Error(`Error: ${response.data.errors[0]}`);
  } else {
    // Get the first user otherwise undefined.
    return response.data.users.length > 0 && response.data.users[0].robots.length > 0
      ? response.data.users[0].robots[0].sessions
      : undefined;
  }
}
