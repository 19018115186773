import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export function CreateIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g style={{ display: "inline", }} transform="translate(0 12.7)" >
                <g style={{ display: "inline", }} transform="translate(-1945 -842.843) scale(.59623)" >
                    <circle className="st1" cx={3286.08} cy={1420.72} r={21.34} style={{ fill: "#2f1c63", }} />
                    <rect x={3286.08} y={1399.37} className="st1" width={8.96} height={42.69} style={{ fill: "#2f1c63", }} />
                    <circle className="st2" cx={3295.03} cy={1420.72} r={21.34} style={{ fill: "#9053ec", }} />
                    <path className="st1" d="m 3298.3,1433.11 v -9.12 h 9.12 c 1.81,0 3.27,-1.46 3.27,-3.27 0,-1.81 -1.46,-3.27 -3.27,-3.27 h -9.12 v 0 -9.12 c 0,-1.81 -1.46,-3.27 -3.27,-3.27 -1.81,0 -3.27,1.46 -3.27,3.27 v 9.12 h -9.12 c -1.81,0 -3.27,1.46 -3.27,3.27 v 0 c 0,1.81 1.46,3.27 3.27,3.27 h 9.12 v 0 9.12 c 0,1.81 1.46,3.27 3.27,3.27 1.81,0 3.27,-1.46 3.27,-3.27 z" style={{ fill: "#2f1c63", }} />
                </g>
            </g>
        </SvgIcon>
    );
}

export function DarkNegativeCreateIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g style={{ display: "inline", }} transform="translate(0 12.7)" >
                <g style={{ display: "inline", }} transform="translate(-1945 -842.843) scale(.59623)" >
                    <circle className="st1" cx={3286.08} cy={1420.72} r={21.34} style={{ fill: "#2f1c63", }} />
                    <rect x={3286.08} y={1399.37} className="st1" width={8.96} height={42.69} style={{ fill: "#2f1c63", }} />
                    <circle className="st2" cx={3295.03} cy={1420.72} r={21.34} style={{ fill: "#fffff", }} />
                    <path className="st1" d="m 3298.3,1433.11 v -9.12 h 9.12 c 1.81,0 3.27,-1.46 3.27,-3.27 0,-1.81 -1.46,-3.27 -3.27,-3.27 h -9.12 v 0 -9.12 c 0,-1.81 -1.46,-3.27 -3.27,-3.27 -1.81,0 -3.27,1.46 -3.27,3.27 v 9.12 h -9.12 c -1.81,0 -3.27,1.46 -3.27,3.27 v 0 c 0,1.81 1.46,3.27 3.27,3.27 h 9.12 v 0 9.12 c 0,1.81 1.46,3.27 3.27,3.27 1.81,0 3.27,-1.46 3.27,-3.27 z" style={{ fill: "#2f1c63", }} />
                </g>
            </g>
        </SvgIcon>
    );
}