import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export function SolveSwarmIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g style={{ display: "inline", }} transform="translate(0 12.7)" >
                <g style={{ display: "inline", }} transform="matrix(.26662 0 0 .26662 -808.644 -633.198)" >
                    <g>
                        <g>
                            <path className="st1" d="m 3080.57,2392.8 h -13.38 c -2.34,0 -4.23,-1.89 -4.23,-4.23 v -13.38 c 0,-2.34 1.89,-4.23 4.23,-4.23 h 13.38 c 2.34,0 4.23,1.89 4.23,4.23 v 13.38 c 0,2.34 -1.89,4.23 -4.23,4.23 z" style={{ fill: "#2f1c63", }} />
                            <path className="st1" d="m 3062.96,2385.73 v 0 c -2.12,0 -3.84,-1.72 -3.84,-3.84 v 0 c 0,-2.12 1.72,-3.84 3.84,-3.84 v 0 z" style={{ fill: "#2f1c63", }} />
                            <path className="st1" d="m 3084.8,2385.73 v 0 -7.69 0 c 2.12,0 3.84,1.72 3.84,3.84 v 0 c 0,2.13 -1.72,3.85 -3.84,3.85 z" style={{ fill: "#2f1c63", }} />
                            <path className="st2" d="m 3086.03,2392.8 h -13.38 c -2.34,0 -4.23,-1.89 -4.23,-4.23 v -13.38 c 0,-2.34 1.89,-4.23 4.23,-4.23 h 13.38 c 2.34,0 4.23,1.89 4.23,4.23 v 13.38 c 0,2.34 -1.89,4.23 -4.23,4.23 z" style={{ fill: "#9053ec", }} />
                            <path className="st2" d="m 3068.43,2385.73 v 0 c -2.12,0 -3.84,-1.72 -3.84,-3.84 v 0 c 0,-2.12 1.72,-3.84 3.84,-3.84 v 0 z" style={{ fill: "#9053ec", }} />
                            <path className="st2" d="m 3090.26,2385.73 v 0 -7.69 0 c 2.12,0 3.84,1.72 3.84,3.84 v 0 c 0.01,2.13 -1.71,3.85 -3.84,3.85 z" style={{ fill: "#9053ec", }} />
                            <path className="st1" d="m 3079.34,2390.07 c 3.76,0 6.81,-3.05 6.81,-6.81 h -13.63 c 0.01,3.76 3.06,6.81 6.82,6.81 z" style={{ fill: "#2f1c63", }} />
                            <path className="st1" d="m 3076.49,2380.43 c 0.32,-0.39 0.51,-0.87 0.51,-1.41 0,-1.23 -1,-2.24 -2.24,-2.24 -1.24,0 -2.24,1 -2.24,2.24 0,0.54 0.2,1.02 0.51,1.41 z" style={{ fill: "#2f1c63", }} />
                            <path className="st1" d="m 3085.65,2380.43 c 0.32,-0.39 0.51,-0.87 0.51,-1.41 0,-1.23 -1,-2.24 -2.24,-2.24 -1.23,0 -2.24,1 -2.24,2.24 0,0.54 0.2,1.02 0.51,1.41 z" style={{ fill: "#2f1c63", }} />
                        </g>
                        <g>
                            <path className="st1" d="m 3120.33,2392.8 h -13.38 c -2.34,0 -4.23,-1.89 -4.23,-4.23 v -13.38 c 0,-2.34 1.89,-4.23 4.23,-4.23 h 13.38 c 2.34,0 4.23,1.89 4.23,4.23 v 13.38 c 0,2.34 -1.89,4.23 -4.23,4.23 z" style={{ fill: "#2f1c63", }} />
                            <path className="st1" d="m 3102.72,2385.73 v 0 c -2.12,0 -3.84,-1.72 -3.84,-3.84 v 0 c 0,-2.12 1.72,-3.84 3.84,-3.84 v 0 z" style={{ fill: "#2f1c63", }} />
                            <path className="st1" d="m 3124.56,2385.73 v 0 -7.69 0 c 2.12,0 3.84,1.72 3.84,3.84 v 0 c 0,2.13 -1.72,3.85 -3.84,3.85 z" style={{ fill: "#2f1c63", }} />
                            <path className="st2" d="m 3125.79,2392.8 h -13.38 c -2.34,0 -4.23,-1.89 -4.23,-4.23 v -13.38 c 0,-2.34 1.89,-4.23 4.23,-4.23 h 13.38 c 2.34,0 4.23,1.89 4.23,4.23 v 13.38 c 0,2.34 -1.89,4.23 -4.23,4.23 z" style={{ fill: "#9053ec", }} />
                            <path className="st2" d="m 3108.18,2385.73 v 0 c -2.12,0 -3.84,-1.72 -3.84,-3.84 v 0 c 0,-2.12 1.72,-3.84 3.84,-3.84 v 0 z" style={{ fill: "#9053ec", }} />
                            <path className="st2" d="m 3130.02,2385.73 v 0 -7.69 0 c 2.12,0 3.84,1.72 3.84,3.84 v 0 c 0.01,2.13 -1.71,3.85 -3.84,3.85 z" style={{ fill: "#9053ec", }} />
                            <path className="st1" d="m 3119.1,2390.07 c 3.76,0 6.81,-3.05 6.81,-6.81 h -13.63 c 0.01,3.76 3.06,6.81 6.82,6.81 z" style={{ fill: "#2f1c63", }} />
                            <path className="st1" d="m 3116.25,2380.43 c 0.32,-0.39 0.51,-0.87 0.51,-1.41 0,-1.23 -1,-2.24 -2.24,-2.24 -1.23,0 -2.24,1 -2.24,2.24 0,0.54 0.2,1.02 0.51,1.41 z" style={{ fill: "#2f1c63", }} />
                            <path className="st1" d="m 3125.4,2380.43 c 0.32,-0.39 0.51,-0.87 0.51,-1.41 0,-1.23 -1,-2.24 -2.24,-2.24 -1.23,0 -2.24,1 -2.24,2.24 0,0.54 0.2,1.02 0.51,1.41 z" style={{ fill: "#2f1c63", }} />
                        </g>
                        <g>
                            <path className="st1" d="m 3080.57,2421.05 h -13.38 c -2.34,0 -4.23,-1.89 -4.23,-4.23 v -13.38 c 0,-2.34 1.89,-4.23 4.23,-4.23 h 13.38 c 2.34,0 4.23,1.89 4.23,4.23 v 13.38 c 0,2.33 -1.89,4.23 -4.23,4.23 z" style={{ fill: "#2f1c63", }} />
                            <path className="st1" d="m 3062.96,2413.97 v 0 c -2.12,0 -3.84,-1.72 -3.84,-3.84 v 0 c 0,-2.12 1.72,-3.84 3.84,-3.84 v 0 z" style={{ fill: "#2f1c63", }} />
                            <path className="st1" d="m 3084.8,2413.97 v 0 -7.69 0 c 2.12,0 3.84,1.72 3.84,3.84 v 0 c 0,2.13 -1.72,3.85 -3.84,3.85 z" style={{ fill: "#2f1c63", }} />
                            <path className="st2" d="m 3086.03,2421.05 h -13.38 c -2.34,0 -4.23,-1.89 -4.23,-4.23 v -13.38 c 0,-2.34 1.89,-4.23 4.23,-4.23 h 13.38 c 2.34,0 4.23,1.89 4.23,4.23 v 13.38 c 0,2.33 -1.89,4.23 -4.23,4.23 z" style={{ fill: "#9053ec", }} />
                            <path className="st2" d="m 3068.43,2413.97 v 0 c -2.12,0 -3.84,-1.72 -3.84,-3.84 v 0 c 0,-2.12 1.72,-3.84 3.84,-3.84 v 0 z" style={{ fill: "#9053ec", }} />
                            <path className="st2" d="m 3090.26,2413.97 v 0 -7.69 0 c 2.12,0 3.84,1.72 3.84,3.84 v 0 c 0.01,2.13 -1.71,3.85 -3.84,3.85 z" style={{ fill: "#9053ec", }} />
                            <path className="st1" d="m 3079.34,2418.31 c 3.76,0 6.81,-3.05 6.81,-6.81 h -13.63 c 0.01,3.76 3.06,6.81 6.82,6.81 z" style={{ fill: "#2f1c63", }} />
                            <path className="st1" d="m 3076.49,2408.67 c 0.32,-0.39 0.51,-0.87 0.51,-1.41 0,-1.23 -1,-2.24 -2.24,-2.24 -1.24,0 -2.24,1 -2.24,2.24 0,0.54 0.2,1.02 0.51,1.41 z" style={{ fill: "#2f1c63", }} />
                            <path className="st1" d="m 3085.65,2408.67 c 0.32,-0.39 0.51,-0.87 0.51,-1.41 0,-1.23 -1,-2.24 -2.24,-2.24 -1.23,0 -2.24,1 -2.24,2.24 0,0.54 0.2,1.02 0.51,1.41 z" style={{ fill: "#2f1c63", }} />
                        </g>
                        <g>
                            <path className="st1" d="m 3120.33,2421.05 h -13.38 c -2.34,0 -4.23,-1.89 -4.23,-4.23 v -13.38 c 0,-2.34 1.89,-4.23 4.23,-4.23 h 13.38 c 2.34,0 4.23,1.89 4.23,4.23 v 13.38 c 0,2.33 -1.89,4.23 -4.23,4.23 z" style={{ fill: "#2f1c63", }} />
                            <path className="st1" d="m 3102.72,2413.97 v 0 c -2.12,0 -3.84,-1.72 -3.84,-3.84 v 0 c 0,-2.12 1.72,-3.84 3.84,-3.84 v 0 z" style={{ fill: "#2f1c63", }} />
                            <path className="st1" d="m 3124.56,2413.97 v 0 -7.69 0 c 2.12,0 3.84,1.72 3.84,3.84 v 0 c 0,2.13 -1.72,3.85 -3.84,3.85 z" style={{ fill: "#2f1c63", }} />
                            <path className="st2" d="m 3125.79,2421.05 h -13.38 c -2.34,0 -4.23,-1.89 -4.23,-4.23 v -13.38 c 0,-2.34 1.89,-4.23 4.23,-4.23 h 13.38 c 2.34,0 4.23,1.89 4.23,4.23 v 13.38 c 0,2.33 -1.89,4.23 -4.23,4.23 z" style={{ fill: "#9053ec", }} />
                            <path className="st2" d="m 3108.18,2413.97 v 0 c -2.12,0 -3.84,-1.72 -3.84,-3.84 v 0 c 0,-2.12 1.72,-3.84 3.84,-3.84 v 0 z" style={{ fill: "#9053ec", }} />
                            <path className="st2" d="m 3130.02,2413.97 v 0 -7.69 0 c 2.12,0 3.84,1.72 3.84,3.84 v 0 c 0.01,2.13 -1.71,3.85 -3.84,3.85 z" style={{ fill: "#9053ec", }} />
                            <path className="st1" d="m 3119.1,2418.31 c 3.76,0 6.81,-3.05 6.81,-6.81 h -13.63 c 0.01,3.76 3.06,6.81 6.82,6.81 z" style={{ fill: "#2f1c63", }} />
                            <path className="st1" d="m 3116.25,2408.67 c 0.32,-0.39 0.51,-0.87 0.51,-1.41 0,-1.23 -1,-2.24 -2.24,-2.24 -1.23,0 -2.24,1 -2.24,2.24 0,0.54 0.2,1.02 0.51,1.41 z" style={{ fill: "#2f1c63", }} />
                            <path className="st1" d="m 3125.4,2408.67 c 0.32,-0.39 0.51,-0.87 0.51,-1.41 0,-1.23 -1,-2.24 -2.24,-2.24 -1.23,0 -2.24,1 -2.24,2.24 0,0.54 0.2,1.02 0.51,1.41 z" style={{ fill: "#2f1c63", }} />
                        </g>
                        <g>
                            <line className="st27" x1={3078.17} y1={2367.19} x2={3078.17} y2={2359.97} style={{ fill: "none", stroke: "#9053ec", strokeWidth: 2.0941999, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                            <circle className="st2" cx={3078.17} cy={2359.14} r={3.03} style={{ fill: "#9053ec", }} />
                        </g>
                        <g>
                            <line className="st27" x1={3116.76} y1={2367.19} x2={3116.76} y2={2359.97} style={{ fill: "none", stroke: "#9053ec", strokeWidth: 2.0941999, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                            <circle className="st2" cx={3116.76} cy={2359.14} r={3.03} style={{ fill: "#9053ec", }} />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path className="st20" d="m 3073.09,2340.26 h -17.23 c -5.48,0 -9.93,4.44 -9.93,9.93 v 17.23" style={{ fill: "none", stroke: "#9053ec", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                            <path className="st20" d="m 3147.05,2367.41 v -17.23 c 0,-5.48 -4.44,-9.93 -9.93,-9.93 h -17.23" style={{ fill: "none", stroke: "#9053ec", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                            <path className="st20" d="m 3045.94,2414.22 v 17.23 c 0,5.48 4.44,9.93 9.93,9.93 h 17.23" style={{ fill: "none", stroke: "#9053ec", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                            <path className="st20" d="m 3119.9,2441.37 h 17.23 c 5.48,0 9.93,-4.44 9.93,-9.93 v -17.23" style={{ fill: "none", stroke: "#9053ec", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                        </g>
                        <circle className="st2" cx={3073.09} cy={2340.26} r={4.87} style={{ fill: "#9053ec", }} />
                        <circle className="st2" cx={3147.05} cy={2367.41} r={4.87} style={{ fill: "#9053ec", }} />
                        <circle className="st2" cx={3119.9} cy={2441.37} r={4.87} style={{ fill: "#9053ec", }} />
                        <circle className="st2" cx={3045.94} cy={2414.22} r={4.87} style={{ fill: "#9053ec", }} />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}