import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export function RawDataIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g style={{ display: "inline", }} transform="translate(0 12.7)" >
                <g style={{ display: "inline", }} transform="matrix(.34398 0 0 .34398 -289.073 -1109.322)" >
                    <g>
                        <circle className="st1" cx={855.23} cy={3220.93} r={10.67} style={{ fill: "#2f1c63", }} />
                        <rect x={855.23} y={3210.26} className="st1" width={4.48} height={21.34} style={{ fill: "#2f1c63", }} />
                        <circle className="st2" cx={859.71} cy={3220.93} r={10.67} style={{ fill: "#9053ec", }} />
                    </g>
                    <g>
                        <circle className="st1" cx={888.48} cy={3200.78} r={6.97} style={{ fill: "#2f1c63", }} />
                        <rect x={888.48} y={3193.81} className="st1" width={2.93} height={13.94} style={{ fill: "#2f1c63", }} />
                        <circle className="st2" cx={891.4} cy={3200.78} r={6.97} style={{ fill: "#9053ec", }} />
                    </g>
                    <g>
                        <circle className="st1" cx={866.51} cy={3268.25} r={12.55} style={{ fill: "#2f1c63", }} />
                        <rect x={866.51} y={3255.7} className="st1" width={5.27} height={25.09} style={{ fill: "#2f1c63", }} />
                        <circle className="st2" cx={871.77} cy={3268.25} r={12.55} style={{ fill: "#9053ec", }} />
                    </g>
                    <g>
                        <circle className="st1" cx={919.52} cy={3243.62} r={10.67} style={{ fill: "#2f1c63", }} />
                        <rect x={919.52} y={3232.94} className="st1" width={4.48} height={21.34} style={{ fill: "#2f1c63", }} />
                        <circle className="st2" cx={924} cy={3243.62} r={10.67} style={{ fill: "#9053ec", }} />
                    </g>
                    <g>
                        <circle className="st1" cx={908.8} cy={3266.01} r={8.43} style={{ fill: "#2f1c63", }} />
                        <rect x={908.8} y={3257.58} className="st1" width={3.54} height={16.87} style={{ fill: "#2f1c63", }} />
                        <circle className="st2" cx={912.34} cy={3266.01} r={8.43} style={{ fill: "#9053ec", }} />
                    </g>
                    <g>
                        <circle className="st1" cx={882.57} cy={3236.9} r={10.67} style={{ fill: "#2f1c63", }} />
                        <rect x={882.57} y={3226.23} className="st1" width={4.48} height={21.34} style={{ fill: "#2f1c63", }} />
                        <circle className="st2" cx={887.05} cy={3236.9} r={10.67} style={{ fill: "#9053ec", }} />
                    </g>
                    <polygon className="st20" points="924,3243.62 912.34,3266.01 870.38,3268.25 859.71,3220.93 891.4,3200.78" style={{ fill: "none", stroke: "#9053ec", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    <line className="st20" x1={887.05} y1={3236.9} x2={891.4} y2={3200.78} style={{ fill: "none", stroke: "#9053ec", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    <line className="st20" x1={870.38} y1={3268.25} x2={887.05} y2={3236.9} style={{ fill: "none", stroke: "#9053ec", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    <line className="st20" x1={859.71} y1={3220.93} x2={887.05} y2={3236.9} style={{ fill: "none", stroke: "#9053ec", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    <line className="st20" x1={922.93} y1={3243.62} x2={887.14} y2={3236.16} style={{ fill: "none", stroke: "#9053ec", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    <line className="st20" x1={912.34} y1={3266.01} x2={887.14} y2={3236.16} style={{ fill: "none", stroke: "#9053ec", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                </g>
            </g>
        </SvgIcon>
    );
}