import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export function DocumentIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g style={{display: "inline"}} transform="translate(0 12.7)">
                <g transform="translate(-177.718 -2542.792) scale(.4421)" style={{display: "inline"}} >
                    <polygon style={{fill: "#2f1c63"}} transform="translate(-2881.63 4420.335)" points="3347.04,1370.07 3296.22,1370.07 3296.22,1311.64 3331.07,1311.64 3347.04,1327.61" className="st1" />
                    <polygon style={{fill: "#9053ec"}} transform="translate(-2892.83 4420.335)" points="3358.24,1370.07 3307.42,1370.07 3307.42,1311.64 3342.27,1311.64 3358.24,1327.61" className="st2" />
                    <polygon style={{fill: "#2f1c63"}} transform="translate(-2892.255 4420.335)" points="3358.24,1327.61 3342.27,1327.61 3342.27,1311.64" className="st1" />
                </g>
            </g>
        </SvgIcon>
    );
}