import { gql } from '@apollo/client';

import { NavAbilityClient } from 'navabilitysdk';
import { Robot } from '../../types/base';
import { GQL_ADD_ROBOT, GQL_GET_ROBOTS } from './graphql/Robots';

/**
 * Add a robot to a user.
 *
 * @export
 * @param {NavAbilityClient} navAbilityClient
 * @param {string} userName The name of the user.
 * @return {*}  {Promise<Robot[]>}
 */
export async function addRobotApi(navAbilityClient: NavAbilityClient, userId: string, label: string, metadata: string): Promise<Robot[]> {
  const response = await navAbilityClient.mutate({
    mutation: gql(`${GQL_ADD_ROBOT}`),
    fetchPolicy: 'network-only',
    variables: {
      USER_ID: userId,
      UNIQUE_KEY: `${userId}.${label}`,
      LABEL: label,
      METADATA: metadata
    },
  });
  if (response.data.errors) {
    throw Error(`Error: ${response.data.errors[0]}`);
  } else {
    console.log(response.data);
    // Return the robot ID.
    const robot = response.data.createRobots.robots[0];
    return robot.id;
  }
}


/**
 * Get all robots for a given user, based on user ID.
 *
 * @export
 * @param {NavAbilityClient} navAbilityClient
 * @param {string} userName The name of the user.
 * @return {*}  {Promise<Robot[]>}
 */
export async function getRobotsApi(navAbilityClient: NavAbilityClient, userLabel: string): Promise<Robot[]> {
  const response = await navAbilityClient.query({
    query: gql(`${GQL_GET_ROBOTS}`),
    fetchPolicy: 'network-only',
    variables: {
      USER_LABEL: userLabel,
    },
  });
  if (response.data.errors) {
    throw Error(`Error: ${response.data.errors[0]}`);
  } else {
    // Get the first user otherwise undefined.
    return response.data.users.length > 0 ? response.data.users[0].robots : undefined;
  }
}
