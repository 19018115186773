import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export function FilterIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g style={{ display: "inline", }} transform="translate(0 12.7)" >
                <g style={{ display: "inline", }} transform="translate(-297.884 -1209.21) scale(.4421)" >
                    <polygon className="st1" points="727.84,2723.38 712.25,2744.72 700.75,2744.72 685.15,2723.38" style={{ fill: "#2f1c63", }} />
                    <rect x={700.75} y={2744.72} className="st1" width={11.51} height={21.34} style={{ fill: "#2f1c63", }} />
                    <polygon className="st2" points="739.04,2723.38 723.45,2744.72 711.94,2744.72 696.35,2723.38" style={{ fill: "#9053ec", }} />
                    <rect x={711.94} y={2744.72} className="st2" width={11.51} height={21.34} style={{ fill: "#9053ec", }} />
                </g>
            </g>
        </SvgIcon>
    )
}

export function NegativeFilterIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g style={{ display: "inline", }} transform="translate(0 12.7)" >
                <g style={{ display: "inline", }} transform="translate(-297.884 -1209.21) scale(.4421)" >
                    <polygon className="st1" points="727.84,2723.38 712.25,2744.72 700.75,2744.72 685.15,2723.38" style={{ fill: "#9053ec", }} />
                    <rect x={700.75} y={2744.72} className="st1" width={11.51} height={21.34} style={{ fill: "#9053ec", }} />
                    <polygon className="st2" points="739.04,2723.38 723.45,2744.72 711.94,2744.72 696.35,2723.38" style={{ fill: "#ffffff", }} />
                    <rect x={711.94} y={2744.72} className="st2" width={11.51} height={21.34} style={{ fill: "#ffffff", }} />
                </g>
            </g>
        </SvgIcon>
    )
}
