import { useApolloClient } from '@apollo/client';
import React from 'react';
import useActions from '../../app/hooks';
import { getIDToken, getIDTokenDecoded } from '../../services/token-storage';

export const NavAbilityUserContext = React.createContext({user: null});

/**
 * This wrapper keeps an eye on the ID token from Cognito
 * and if it changes (either gets updated or expires) the user
 * is cleared.
 * 
 * -----
 * NOTE: This needs to go in the layout, it cannot go in the gatsby-browser.js code, 
 * that doesn't seem to update correctly every time.
 * ------
 * 
 * @export
 * @return {*} 
 */
export default function NavAbilityUserProvider(props: {children?: React.ReactNode}) {
  const actions = useActions();
  const globalState = useApolloClient();

  // If the ID token changes, get the user.
  const idToken = getIDTokenDecoded();

  React.useEffect(() => {
    // On mounting get the latest user
    actions.getSelf({ userToken: idToken, apolloClient: globalState });  
  }, [getIDToken()]);

  return (
    <NavAbilityUserContext.Provider value={{user: null}}>
      {props.children}
    </NavAbilityUserContext.Provider>
  );
}
