import { gql, useApolloClient } from "@apollo/client";
import { Button, Card, CardContent, CardHeader, List, ListItem, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

const DEVICE_QUERY = gql`
query GetDevice {
    account @client {
        userId
    }
    device @client {
        robotId
        sessionId
    }
}`;

const FILTERS_QUERY = gql`
query GetFilters {
    filters @client {
        twin {
            userId
            robotId
            sessionId
            variableLabel
            requestId
        }
        swarm {
            environmentIds
            userIds
            robotIds
            sessionIds
        }
    }
}`;

const FilterContent = () => {
    const globalState = useApolloClient()
    const [filters, setFilters] = useState({twin:{userId:'',robotId:'',sessionId:'',variableLabel:'',requestId:''},swarm:{environmentIds:[],userIds:[],robotIds:[],sessionIds:[]}})
    useEffect(() => {
        globalState.query({query: FILTERS_QUERY}).then(result => {
            setFilters(result.data.filters)
        })
    })
    const updateGlobalState = (filters: any) => {
        globalState.writeQuery({
            query: FILTERS_QUERY,
            data: {
                filters: filters
            }
        })
    }
    const handleUserChange = (event:any) => {
        const update = {...filters, twin: {...filters.twin, userId: event.target.value}}
        setFilters(update)
        updateGlobalState(update)
    }
    const handleRobotChange = (event:any) => {
        const update = {...filters, twin: {...filters.twin, robotId: event.target.value}}
        setFilters(update)
        updateGlobalState(update)
    }
    const handleSessionChange = (event:any) => {
        const update = {...filters, twin: {...filters.twin, sessionId: event.target.value}}
        setFilters(update)
        updateGlobalState(update)
    }
    const handleVariableChange = (event:any) => {
        const update = {...filters, twin: {...filters.twin, variableLabel: event.target.value}}
        setFilters(update)
        updateGlobalState(update)
    }
    const handleRequestChange = (event:any) => {
        const update = {...filters, twin: {...filters.twin, requestId: event.target.value}}
        setFilters(update)
        updateGlobalState(update)
    }
    const handleEnvironmentsChange = (event:any) => {
        const environmentIds = event.target.value.replaceAll(' ','').split(',')
        const update = {...filters, swarm: {...filters.swarm, environmentIds: environmentIds}}
        setFilters(update)
        updateGlobalState(update)
    }
    const handleUsersChange = (event:any) => {
        const userIds = event.target.value.replaceAll(' ','').split(',')
        const update = {...filters, swarm: {...filters.swarm, userIds: userIds}}
        setFilters(update)
        updateGlobalState(update)
    }
    const handleRobotsChange = (event:any) => {
        const robotIds = event.target.value.replaceAll(' ','').split(',')
        const update = {...filters, swarm: {...filters.swarm, robotIds: robotIds}}
        setFilters(update)
        updateGlobalState(update)
    }
    const handleSessionsChange = (event:any) => {
        const sessionIds = event.target.value.replaceAll(' ','').split(',')
        const update = {...filters, swarm: {...filters.swarm, sessionIds: sessionIds}}
        setFilters(update)
        updateGlobalState(update)
    }
    const filterOnDevice = async () => {
        const result = await globalState.query({query: DEVICE_QUERY})
        const update = {
            ...filters, 
            twin: {
                ...filters.twin,
                userId:result.data.account.userId,
                robotId:result.data.device.robotId,
                sessionId:result.data.device.sessionId,
                variableLabel:'',
                requestId: ''
            }
        }
        setFilters(update)
        updateGlobalState(update)
    }

    return (
        <>
            <Card>
                <CardHeader title="Global Filters" />
                <CardContent>
                    <List>
                        <ListItem> <TextField label="UserId" value={filters.twin.userId} onChange={handleUserChange}/></ListItem>
                        <ListItem> <TextField label="Robot Starts With" value={filters.twin.robotId} onChange={handleRobotChange}/></ListItem>
                        <ListItem> <TextField label="Session Starts With" value={filters.twin.sessionId} onChange={handleSessionChange}/></ListItem>
                        <ListItem> <TextField label="Variable Label Starts With" value={filters.twin.variableLabel} onChange={handleVariableChange}/></ListItem>
                        <ListItem> <TextField label="Request Starts With" value={filters.twin.requestId} onChange={handleRequestChange}/></ListItem>
                    </List>
                </CardContent>         
            </Card>
            {/* <Card>
                <CardHeader title="Swarm Selection" />
                <CardContent>
                    <ListItem> <TextField label="Environments" value={filters.swarm.environmentIds.join(",")} onChange={handleEnvironmentsChange}/></ListItem>
                    <ListItem> <TextField label="Users" value={filters.swarm.userIds.join(",")} onChange={handleUsersChange}/></ListItem>
                    <ListItem> <TextField label="Robots" value={filters.swarm.robotIds.join(",")} onChange={handleRobotsChange}/></ListItem>
                    <ListItem> <TextField label="Sessions" value={filters.swarm.sessionIds.join(",")} onChange={handleSessionsChange}/></ListItem>
                </CardContent>
            </Card> */}
            <Card>
                <CardHeader title="Quick Filters" />
                <CardContent>
                    <List>
                        <ListItem> <Button variant="contained" color="secondary" onClick={filterOnDevice}> This Device </Button> </ListItem>
                    </List>
                </CardContent>
            </Card>
                
            
        </>
    )
}

export default FilterContent