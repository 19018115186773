import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import type { AppDispatch, RootState } from './store';

import { addMap, addRobot, addWorkflow, deleteMap, deleteRobot, deleteWorkflow, getMaps, getRobots, getRobotsLatest, getSelf, getSessions, getUser, getWorkflows, updateRobot } from './actions';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(
    {
      // Users
      getSelf,
      getUser,
      // Home
      getRobotsLatest,
      // Robots
      addRobot,
      getRobots,
      updateRobot,
      deleteRobot,
      // Sessions
      getSessions,
      // Maps
      getMaps,
      addMap,
      deleteMap,
      // Workflows
      getWorkflows,
      addWorkflow,
      deleteWorkflow
    },
    dispatch,
  );
};

export default useActions;
