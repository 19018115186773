import { ApolloClient, createHttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { CachePersistor } from 'apollo-cache-persist';
import fetch from 'isomorphic-fetch';

import { getIDToken } from '../../services/token-storage';
import { INITIAL_STATE, INITIAL_STATE_QUERY, SCHEMA_VERSION } from './schema';

export const client = () => {
  const storage: any = typeof window !== `undefined` ? window.localStorage : null;
  const cache: any = new InMemoryCache();

  const resetStore = () => {
    return cache.writeQuery({ query: INITIAL_STATE_QUERY, data: INITIAL_STATE });
  };

  const restoreStore = () => {
    if (storage) {
      const persistor = new CachePersistor({
        cache,
        storage,
      });

      if (storage.getItem('SCHEMA_VERSION') === SCHEMA_VERSION) {
        persistor.restore();
      }
      storage.setItem('SCHEMA_VERSION', SCHEMA_VERSION);
      resetStore();
    }
  };

  const authLink = setContext((_, { headers }) => {
    let token = getIDToken();
    if (!token) return headers;
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  });

  const remoteHttpUrl = process.env.GATSBY_NAVABILITY_API_URL || '';
  const remoteWsUrl = process.env.GATSBY_NAVABILITY_WSS_URL || '';
  const basicHttpLink: any = createHttpLink({ uri: remoteHttpUrl, fetch: fetch });
  const httpLink = authLink.concat(basicHttpLink);
  //If building, ignore below.
  // if (process.browser) return null;
  var link = null;
  if (typeof window !== 'undefined') {
    const wsLink = new WebSocketLink({
      uri: remoteWsUrl,
      options: {
        reconnect: true,
        connectionParams: () => {
          let token = getIDToken();
          return {
            authToken: token ? `Bearer ${token}` : '',
          };
        },
      },
      webSocketImpl: null,
    });
    link = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      },
      wsLink,
      httpLink,
    );
  } else {
    link = httpLink;
  }
  restoreStore();
  const client = new ApolloClient<InMemoryCache>({ link: link, cache });
  client.onResetStore(() => resetStore());
  client.onClearStore(() => resetStore());
  return client;
};
