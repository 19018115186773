import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export function HexagonalIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g style={{ display: "inline", }} transform="translate(0 12.7)" >
                <g style={{ display: "inline", }} transform="translate(-1320.534 -459.53) scale(.4421)" >
                    <polygon className="st1" points="3007.33,1027.65 2995,1049 3007.33,1070.35 3031.99,1070.35 3044.31,1049 3031.99,1027.65" style={{ fill: "#2f1c63", }} />
                    <polygon className="st2" points="3018.53,1027.65 3006.2,1049 3018.53,1070.35 3043.18,1070.35 3055.51,1049 3043.18,1027.65" style={{ fill: "#9053ec", }} />
                </g>
            </g>
        </SvgIcon>
    );
}