import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#301c63',
    },
    secondary: {
      main: '#9053ec',
    },
    info: {
      main: '#fed100',
    },
    text: {
      primary: '#616284',
    },
    background: {
      default: '#f3fefb',
    },
  },
  typography: {
    h1: {
      color: '#301c63',
      fontWeight: 'bold',
      fontSize: '34px',
      letterSpacing: '3px',
    },
    h2: {
      color: '#301c63',
      fontWeight: 'bold',
      fontSize: '30px',
    },
    h3: {
      color: '#301c63',
      fontSize: '22px',
    },
    h4: {
      color: '#301c63',
      fontSize: '18px',
    },
    subtitle1: {
      color: '#301c63',
      fontSize: '18px',
    },
    body1: {
      color: '#616284',
      fontSize: '15px',
    },
    body2: {
      color: '#9053ec',
      fontSize: '15px',
      fontWeight: 'bold',
    },
    fontFamily: ['montserrat', 'sans-serif'].join(','),
  },
});

export default theme;
