import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export function HistoryIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g style={{ display: "inline", }} transform="translate(0 12.7)" >
                <g style={{ display: "inline", }} transform="translate(-369.188 -1371.104) scale(.4421)" >
                    <rect x={843.07} y={3077.14} className="st1" width={53.59} height={67.55} style={{ fill: "#2f1c63", }} />
                    <rect x={850.1} y={3077.14} className="st2" width={53.59} height={67.55} style={{ fill: "#9053ec", }} />
                    <line className="st24" x1={879.19} y1={3088.07} x2={858.21} y2={3088.07} style={{ fill: "none", stroke: "#2f1c63", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    <line className="st24" x1={879.19} y1={3099.26} x2={858.21} y2={3099.26} style={{ fill: "none", stroke: "#2f1c63", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    <line className="st24" x1={879.19} y1={3110.46} x2={858.21} y2={3110.46} style={{ fill: "none", stroke: "#2f1c63", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    <line className="st24" x1={879.19} y1={3121.66} x2={858.21} y2={3121.66} style={{ fill: "none", stroke: "#2f1c63", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    <line className="st24" x1={879.19} y1={3132.85} x2={858.21} y2={3132.85} style={{ fill: "none", stroke: "#2f1c63", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    <polyline className="st24" points="888.05,3086.55 890.73,3089.23 895.72,3084.24" style={{ fill: "none", stroke: "#2f1c63", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    <polyline className="st24" points="888.05,3097.75 890.73,3100.42 895.72,3095.43" style={{ fill: "none", stroke: "#2f1c63", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    <polyline className="st24" points="888.05,3108.95 890.73,3111.62 895.72,3106.63" style={{ fill: "none", stroke: "#2f1c63", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    <polyline className="st24" points="888.05,3120.14 890.73,3122.82 895.72,3117.83" style={{ fill: "none", stroke: "#2f1c63", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    <polyline className="st24" points="888.05,3131.34 890.73,3134.01 895.72,3129.02" style={{ fill: "none", stroke: "#2f1c63", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                </g>
            </g>
        </SvgIcon>
    );
}