import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export function SolveThatIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g style={{ display: "inline", }} transform="translate(0 12.7)" >
                <g style={{ display: "inline", }} transform="matrix(.25066 0 0 .25066 -790.2 -511.548)" >
                    <g>
                        <g>
                            <path className="st1" d="m 3227.27,2080.51 h -21.46 c -3.75,0 -6.78,-3.04 -6.78,-6.78 v -21.46 c 0,-3.75 3.04,-6.78 6.78,-6.78 h 21.46 c 3.75,0 6.78,3.04 6.78,6.78 v 21.46 c 0,3.74 -3.04,6.78 -6.78,6.78 z" style={{ fill: "#2f1c63", }} />
                            <path className="st1" d="m 3199.02,2069.16 v 0 c -3.4,0 -6.16,-2.76 -6.16,-6.16 v 0 c 0,-3.4 2.76,-6.16 6.16,-6.16 v 0 z" style={{ fill: "#2f1c63", }} />
                            <path className="st1" d="m 3234.05,2069.16 v 0 -12.33 0 c 3.4,0 6.16,2.76 6.16,6.16 v 0 c 0,3.41 -2.76,6.17 -6.16,6.17 z" style={{ fill: "#2f1c63", }} />
                            <path className="st2" d="m 3236.02,2080.51 h -21.46 c -3.75,0 -6.78,-3.04 -6.78,-6.78 v -21.46 c 0,-3.75 3.04,-6.78 6.78,-6.78 h 21.46 c 3.75,0 6.78,3.04 6.78,6.78 v 21.46 c 0.01,3.74 -3.03,6.78 -6.78,6.78 z" style={{ fill: "#9053ec", }} />
                            <path className="st2" d="m 3207.78,2069.16 v 0 c -3.4,0 -6.16,-2.76 -6.16,-6.16 v 0 c 0,-3.4 2.76,-6.16 6.16,-6.16 v 0 z" style={{ fill: "#9053ec", }} />
                            <path className="st2" d="m 3242.81,2069.16 v 0 -12.33 0 c 3.4,0 6.16,2.76 6.16,6.16 v 0 c 0,3.41 -2.76,6.17 -6.16,6.17 z" style={{ fill: "#9053ec", }} />
                            <path className="st1" d="m 3225.3,2076.13 c 6.04,0 10.93,-4.89 10.93,-10.93 h -21.86 c 0,6.04 4.89,10.93 10.93,10.93 z" style={{ fill: "#2f1c63", }} />
                            <path className="st1" d="m 3220.72,2060.67 c 0.51,-0.62 0.82,-1.4 0.82,-2.26 0,-1.98 -1.61,-3.59 -3.59,-3.59 -1.98,0 -3.59,1.61 -3.59,3.59 0,0.86 0.32,1.64 0.82,2.26 z" style={{ fill: "#2f1c63", }} />
                            <path className="st1" d="m 3235.4,2060.67 c 0.51,-0.62 0.82,-1.4 0.82,-2.26 0,-1.98 -1.61,-3.59 -3.59,-3.59 -1.98,0 -3.59,1.61 -3.59,3.59 0,0.86 0.32,1.64 0.82,2.26 z" style={{ fill: "#2f1c63", }} />
                        </g>
                        <circle className="st2" cx={3221.54} cy={2031.92} r={4.87} style={{ fill: "#9053ec", }} />
                        <line className="st20" x1={3221.54} y1={2032.25} x2={3221.54} y2={2041.33} style={{ fill: "none", stroke: "#9053ec", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    </g>
                    <g>
                        <g>
                            <path className="st20" d="m 3196.58,2007.11 h -17.23 c -5.48,0 -9.93,4.44 -9.93,9.93 v 17.23" style={{ fill: "none", stroke: "#9053ec", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                            <path className="st20" d="m 3270.54,2034.26 v -17.23 c 0,-5.48 -4.44,-9.93 -9.93,-9.93 h -17.23" style={{ fill: "none", stroke: "#9053ec", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                            <path className="st20" d="m 3169.43,2081.07 v 17.23 c 0,5.48 4.44,9.93 9.93,9.93 h 17.23" style={{ fill: "none", stroke: "#9053ec", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                            <path className="st20" d="m 3243.38,2108.22 h 17.23 c 5.48,0 9.93,-4.44 9.93,-9.93 v -17.23" style={{ fill: "none", stroke: "#9053ec", strokeWidth: 3.35879993, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                        </g>
                        <circle className="st2" cx={3196.58} cy={2007.11} r={4.87} style={{ fill: "#9053ec", }} />
                        <circle className="st2" cx={3270.54} cy={2034.26} r={4.87} style={{ fill: "#9053ec", }} />
                        <circle className="st2" cx={3243.38} cy={2108.22} r={4.87} style={{ fill: "#9053ec", }} />
                        <circle className="st2" cx={3169.43} cy={2081.07} r={4.87} style={{ fill: "#9053ec", }} />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}