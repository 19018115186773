import { Factor, Variable } from "navabilitysdk";

export interface SentinelNode {
  id: string
  label: string
  metadata: string
  createdTimestamp: string
  lastUpdatedTimestamp: string
  _version: string
}

export interface User extends SentinelNode {
  sub: string
  givenName: string
  familyName: string
  status: string
  lastAuthenticatedTimestamp: string
  robots?: [Robot]
}

export interface Robot extends SentinelNode {
  userId?: string
  sessions?: [Session]
}

export interface Session extends SentinelNode {
  userLabel: string
  robotLabel: string
  originLatitude?: string
  originLongitude?: string
  numVariables?: number
  numFactors?: number
  variables?: Variable[]
  factors?: Factor[]
}

export const GUEST_USER: User = {
  id: 'aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa',
  label: 'guest@navability.io',
  sub: 'NULL',
  givenName: 'Guest',
  familyName: '',
  metadata: '',
  status: 'active',
  _version: '0.20.0',
  createdTimestamp: Date.now().toString(),
  lastUpdatedTimestamp: Date.now().toString(),
  lastAuthenticatedTimestamp: Date.now().toString()
}