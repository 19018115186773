import { createAction } from '@reduxjs/toolkit';
import { NavAbilityClient } from 'navabilitysdk';
import { Token } from '../types/auth';
import { Robot, User } from '../types/base';
import { Map, MapData } from '../types/mapper/maps';
import { Workflow } from '../types/mapper/workflows';

// User
export const getSelf = createAction<{ apolloClient: NavAbilityClient; userToken: Token | null }>('getSelf');
export const getUser = createAction<{ apolloClient: NavAbilityClient; label: string }>('getUser');

// Home
export const getRobotsLatest = createAction<{ apolloClient: NavAbilityClient; user: User }>('getRobotsLatest');

// Robots
export const addRobot = createAction<{
  apolloClient: NavAbilityClient;
  user: User;
  robotRequest: { label: string; metadata: string };
}>('addRobot');
export const getRobots = createAction<{ apolloClient: NavAbilityClient; user: User }>('getRobots');
export const updateRobot = createAction<{ apolloClient: NavAbilityClient; robot: Robot }>('updateRobot');
export const deleteRobot = createAction<{ apolloClient: NavAbilityClient; robot: Robot }>('deleteRobot');

// Sessions
export const getSessions = createAction<{ apolloClient: NavAbilityClient; userLabel: string; robotLabel: string }>(
  'getSessions',
);

// Maps
export const getMaps = createAction<{ apolloClient: NavAbilityClient; user: User }>('getMaps');
export const addMap = createAction<{
  user: User;
  apolloClient: NavAbilityClient;
  label: string;
  description: string;
  data: MapData;
}>('addMap');
export const deleteMap = createAction<{ apolloClient: NavAbilityClient; user: User; mapId: string }>('deleteMap');

// Workflows
export const getWorkflows = createAction<{ apolloClient: NavAbilityClient; user: User }>('getWorkflows');
export const addWorkflow = createAction<{
  apolloClient: NavAbilityClient;
  user: User;
  map: Map;
  workflow: Workflow;
}>('addWorkflow');
export const deleteWorkflow = createAction<{ apolloClient: NavAbilityClient; user: User; workflowId: string }>('deleteWorkflow');
