import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export function SendIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g transform="translate(0 -263.133)" style={{ display: "inline", }} >
                <g transform="translate(-11.803 -211.78) scale(1.67093)">
                    <path className="st1" d="m 24.093395,294.35415 -2.333625,-2.33362 -1.457855,-1.45786 v -2.27806 h -2.963333 c -0.423333,0 -0.84402,0.1614 -1.166812,0.48419 -0.642938,0.64293 -0.642938,1.68804 0,2.33362 l 3.251729,3.25173 -3.251729,3.25173 c -0.642938,0.64294 -0.642938,1.68804 0,2.33363 0.322792,0.32279 0.743479,0.48418 1.166812,0.48418 h 2.963333 v -2.27806 l 1.457855,-1.45785 z" style={{ display: "inline", fill: "#2f1c63", strokeWidth: 0.26458332, }} />
                    <path className="st2" d="m 19.137749,291.10242 3.251729,3.25173 -3.251729,3.25173 c -0.642938,0.64294 -0.642938,1.68804 0,2.33363 v 0 c 0.642937,0.64293 1.688041,0.64293 2.333625,0 l 3.251729,-3.25173 2.333625,-2.33363 -2.333625,-2.33362 -3.251729,-3.25173 c -0.642938,-0.64294 -1.688042,-0.64294 -2.333625,0 v 0 c -0.645584,0.64558 -0.645584,1.69068 0,2.33362 z" style={{ display: "inline", fill: "#9053ec", strokeWidth: 0.26458332, }} />
                    <line className="st20" x1={17.323} y1={294.354} x2={10.753} y2={294.354} style={{ display: "inline", fill: "none", stroke: "#9053ec", strokeWidth: 0.88868248, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    <line className="st20" x1={14.359} y1={291.505} x2={7.79} y2={291.505} style={{ display: "inline", fill: "none", stroke: "#9053ec", strokeWidth: 0.88868248, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    <line className="st20" x1={14.359} y1={297.206} x2={7.79} y2={297.206} style={{ display: "inline", fill: "none", stroke: "#9053ec", strokeWidth: 0.88868248, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                </g>
            </g>
        </SvgIcon>
    );
}

export function DarkNegativeSendIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g transform="translate(0 -263.133)" style={{ display: "inline", }} >
                <g transform="translate(-11.803 -211.78) scale(1.67093)">
                    <path className="st1" d="m 24.093395,294.35415 -2.333625,-2.33362 -1.457855,-1.45786 v -2.27806 h -2.963333 c -0.423333,0 -0.84402,0.1614 -1.166812,0.48419 -0.642938,0.64293 -0.642938,1.68804 0,2.33362 l 3.251729,3.25173 -3.251729,3.25173 c -0.642938,0.64294 -0.642938,1.68804 0,2.33363 0.322792,0.32279 0.743479,0.48418 1.166812,0.48418 h 2.963333 v -2.27806 l 1.457855,-1.45785 z" style={{ display: "inline", fill: "#2f1c63", strokeWidth: 0.26458332, }} />
                    <path className="st2" d="m 19.137749,291.10242 3.251729,3.25173 -3.251729,3.25173 c -0.642938,0.64294 -0.642938,1.68804 0,2.33363 v 0 c 0.642937,0.64293 1.688041,0.64293 2.333625,0 l 3.251729,-3.25173 2.333625,-2.33363 -2.333625,-2.33362 -3.251729,-3.25173 c -0.642938,-0.64294 -1.688042,-0.64294 -2.333625,0 v 0 c -0.645584,0.64558 -0.645584,1.69068 0,2.33362 z" style={{ display: "inline", fill: "#ffffff", strokeWidth: 0.26458332, }} />
                    <line className="st20" x1={17.323} y1={294.354} x2={10.753} y2={294.354} style={{ display: "inline", fill: "none", stroke: "#ffffff", strokeWidth: 0.88868248, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    <line className="st20" x1={14.359} y1={291.505} x2={7.79} y2={291.505} style={{ display: "inline", fill: "none", stroke: "#ffffff", strokeWidth: 0.88868248, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                    <line className="st20" x1={14.359} y1={297.206} x2={7.79} y2={297.206} style={{ display: "inline", fill: "none", stroke: "#ffffff", strokeWidth: 0.88868248, strokeLinecap: "round", strokeMiterlimit: 10, }} />
                </g>
            </g>
        </SvgIcon>
    )
}