import { call, put } from 'redux-saga/effects';
import { setStatus, setWorkflows } from '../../global/state/workflow-slice';
import { addWorkflowApi, deleteWorkflowApi, getWorkflowsApi } from '../../services/mapper/workflows-api';
import * as actions from '../actions';

/**
 * Get the current workflow information.
 *
 * @param {ReturnType<typeof actions.getWorkflows>} action
 * @return {*}
 */
export function* getWorkflows(action: ReturnType<typeof actions.getWorkflows>) {
  try {
    yield put(setStatus({ status: 'loading', error: undefined }));

    const workflows = yield call(getWorkflowsApi, action.payload.apolloClient, action.payload.user);
    // console.log(workflows);

    // Set the workflows in the slice.
    yield put(setWorkflows(workflows));

    yield put(setStatus({ status: 'idle', error: undefined }));
  } catch (e) {
    console.log(e);
    yield put(setStatus({ status: 'error', error: String(e) }));
    return;
  }
}

/**
 * Add a workflow.
 *
 * @param {ReturnType<typeof actions.addWorkflow>} action
 * @return {*}
 */
export function* addWorkflow(action: ReturnType<typeof actions.addWorkflow>) {
  try {
    yield put(setStatus({ status: 'loading', error: undefined }));

    console.log('In the Workflows Saga');

    const newWorkflowId = yield call(
      addWorkflowApi,
      action.payload.apolloClient,
      action.payload.user,
      action.payload.map,
      action.payload.workflow,
    );

    yield put(setStatus({ status: 'idle', error: undefined }));

    // Reget all the workflows.
    yield put(actions.getWorkflows({ apolloClient: action.payload.apolloClient, user: action.payload.user }));
  } catch (e) {
    console.log(e);
    yield put(setStatus({ status: 'error', error: String(e) }));
    return;
  }
}

/**
 * Delete a workflow.
 *
 * @param {ReturnType<typeof actions.addWorkflow>} action
 * @return {*}
 */
export function* deleteWorkflow(action: ReturnType<typeof actions.deleteWorkflow>) {
  try {
    yield put(setStatus({ status: 'loading', error: undefined }));

    yield call(deleteWorkflowApi, action.payload.apolloClient, action.payload.user, action.payload.workflowId);

    yield put(setStatus({ status: 'idle', error: undefined }));

    // Reget all the workflows.
    yield put(actions.getWorkflows({ apolloClient: action.payload.apolloClient, user: action.payload.user }));
  } catch (e) {
    console.log(e);
    yield put(setStatus({ status: 'error', error: String(e) }));
    return;
  }
}
