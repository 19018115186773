import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export function CameraIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g style={{display: "inline"}} transform="translate(0 12.7)">
                <g style={{display: "inline"}} transform="translate(-307.858 -613.269) scale(.4421)">
                    <path className="st1" d="m 748.91,1383.76 h -3.6 v -2.25 c 0,-3.95 -3.2,-7.15 -7.15,-7.15 h -18.09 c -3.95,0 -7.15,3.2 -7.15,7.15 v 2.25 h -3.6 c -3.95,0 -7.15,3.2 -7.15,7.15 v 28.22 h 53.88 v -28.22 c 0.01,-3.95 -3.19,-7.15 -7.14,-7.15 z" style={{fill: "#2f1c63"}} />
                    <path className="st2" d="m 760,1383.76 h -3.6 v -2.25 c 0,-3.95 -3.2,-7.15 -7.15,-7.15 h -18.09 c -3.95,0 -7.15,3.2 -7.15,7.15 v 2.25 h -3.6 c -3.95,0 -7.15,3.2 -7.15,7.15 v 28.22 h 53.88 v -28.22 c 0.01,-3.95 -3.19,-7.15 -7.14,-7.15 z" style={{ fill: "#9053ec"}} />
                    <circle className="st1" cx={740.21} cy={1400.7} r={12.31} style={{fill: "#2f1c63"}} />
                </g>
            </g>
        </SvgIcon>
    );
}