import { combineReducers } from 'redux';
import factorgraphVizReducer from '../components/factor-graph/FactorGraphVizSlice';
import homeReducer from '../components/home/HomeSlice';
import robotsReducer from '../components/robots/RobotsSlice';
import mapsReducer from '../global/state/maps-slice';
import userReducer from '../global/state/user-slice';
import workflowsReducer from '../global/state/workflow-slice';

const rootReducer = combineReducers({
  user: userReducer,
  home: homeReducer,
  maps: mapsReducer,
  workflows: workflowsReducer,
  robotsView: robotsReducer,
  factorgraphViz: factorgraphVizReducer,
  // filter: filterReducer,
  // pageState: pageStateReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
