import { createSlice, PayloadAction } from '@reduxjs/toolkit'; //eslint-disable-line sort-imports
import { RootState } from '../../app/store';
import { Robot } from '../../types/base';

export interface HomeState {
  robotsLatest: Robot[] | undefined;
  status: 'idle' | 'loading' | 'error';
  error: string | undefined;
}

const initialState: HomeState = {
  robotsLatest: undefined,
  status: 'idle',
  error: undefined,
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setStatus: (state, action: PayloadAction<{ status: 'idle' | 'loading' | 'error'; error: undefined | string }>) => {
      state.status = action.payload.status;
      state.error = action.payload.error;
    },
    setRobotsLatest: (state, action: PayloadAction<Robot[]>) => {
      state.robotsLatest = action.payload;
    },
  },
});

export const { setStatus, setRobotsLatest } = homeSlice.actions;

//Selectors
export const selectHome = (state: RootState) => state.home;

export default homeSlice.reducer;
