import { call, put } from 'redux-saga/effects';
import { setRobotsLatest, setStatus } from '../../components/home/HomeSlice';
import { getRobotsLatestApi } from '../../services/home/home-api';
import * as actions from '../actions';

/**
 * Get latest robots for the user.
 *
 * @param {ReturnType<typeof actions.login>} action
 * @return {*}
 */
export function* getRobotsLatest(action: ReturnType<typeof actions.getRobots>) {
  try {
    yield put(setStatus({ status: 'loading', error: undefined }));

    const robots = yield call(
      getRobotsLatestApi, 
      action.payload.apolloClient, 
      action.payload.user.label);
    
    // Set the robots in the slice.
    yield put(setRobotsLatest(robots));

    yield put(setStatus({ status: 'idle', error: undefined }));

  } catch (e) {
    console.log(e);
    yield put(setStatus({ status: 'error', error: String(e) }));
    return;
  }
}

export function* updateRobot(action: ReturnType<typeof actions.updateRobot>) {
}

export function* deleteRobot(action: ReturnType<typeof actions.deleteRobot>) {
}
