import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export function MenuIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g style={{ display: "inline" }} transform="translate(0 12.7)" >
                <g style={{ display: "inline" }} transform="translate(-1306.004 -1551.18) scale(.4421)" >
                    <path className="st1" d="m 3007.62,3496.89 h -41.66 c -2.37,0 -4.3,1.92 -4.3,4.3 v 0 c 0,2.37 1.92,4.3 4.3,4.3 h 41.66 c 2.37,0 4.3,-1.92 4.3,-4.3 v 0 c 0,-2.37 -1.92,-4.3 -4.3,-4.3 z" style={{fill: "#2f1c63"}} />
                    <path className="st1" d="m 3007.62,3513.94 h -41.66 c -2.37,0 -4.3,1.92 -4.3,4.3 v 0 c 0,2.37 1.92,4.3 4.3,4.3 h 41.66 c 2.37,0 4.3,-1.92 4.3,-4.3 v 0 c 0,-2.38 -1.92,-4.3 -4.3,-4.3 z" style={{ fill: "#2f1c63", }} />
                    <path className="st1" d="m 3007.62,3530.98 h -41.66 c -2.37,0 -4.3,1.92 -4.3,4.3 v 0 c 0,2.37 1.92,4.3 4.3,4.3 h 41.66 c 2.37,0 4.3,-1.92 4.3,-4.3 v 0 c 0,-2.37 -1.92,-4.3 -4.3,-4.3 z" style={{ fill: "#2f1c63", }} />
                    <path className="st2" d="m 3018.82,3496.89 h -41.66 c -2.37,0 -4.3,1.92 -4.3,4.3 v 0 c 0,2.37 1.92,4.3 4.3,4.3 h 41.66 c 2.37,0 4.3,-1.92 4.3,-4.3 v 0 c 0,-2.37 -1.93,-4.3 -4.3,-4.3 z" style={{ fill: "#9053ec", }} />
                    <path className="st2" d="m 3018.82,3513.94 h -41.66 c -2.37,0 -4.3,1.92 -4.3,4.3 v 0 c 0,2.37 1.92,4.3 4.3,4.3 h 41.66 c 2.37,0 4.3,-1.92 4.3,-4.3 v 0 c 0,-2.38 -1.93,-4.3 -4.3,-4.3 z" style={{ fill: "#9053ec", }} />
                    <path className="st2" d="m 3018.82,3530.98 h -41.66 c -2.37,0 -4.3,1.92 -4.3,4.3 v 0 c 0,2.37 1.92,4.3 4.3,4.3 h 41.66 c 2.37,0 4.3,-1.92 4.3,-4.3 v 0 c 0,-2.37 -1.93,-4.3 -4.3,-4.3 z" style={{ fill: "#9053ec", }} />
                </g>
            </g>
        </SvgIcon>
    );
}

export function NegativeMenuIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g style={{ display: "inline" }} transform="translate(0 12.7)" >
                <g style={{ display: "inline" }} transform="translate(-1306.004 -1551.18) scale(.4421)" >
                    <path className="st1" d="m 3007.62,3496.89 h -41.66 c -2.37,0 -4.3,1.92 -4.3,4.3 v 0 c 0,2.37 1.92,4.3 4.3,4.3 h 41.66 c 2.37,0 4.3,-1.92 4.3,-4.3 v 0 c 0,-2.37 -1.92,-4.3 -4.3,-4.3 z" style={{fill: "#9053ec"}} />
                    <path className="st1" d="m 3007.62,3513.94 h -41.66 c -2.37,0 -4.3,1.92 -4.3,4.3 v 0 c 0,2.37 1.92,4.3 4.3,4.3 h 41.66 c 2.37,0 4.3,-1.92 4.3,-4.3 v 0 c 0,-2.38 -1.92,-4.3 -4.3,-4.3 z" style={{ fill: "#9053ec", }} />
                    <path className="st1" d="m 3007.62,3530.98 h -41.66 c -2.37,0 -4.3,1.92 -4.3,4.3 v 0 c 0,2.37 1.92,4.3 4.3,4.3 h 41.66 c 2.37,0 4.3,-1.92 4.3,-4.3 v 0 c 0,-2.37 -1.92,-4.3 -4.3,-4.3 z" style={{ fill: "#9053ec", }} />
                    <path className="st2" d="m 3018.82,3496.89 h -41.66 c -2.37,0 -4.3,1.92 -4.3,4.3 v 0 c 0,2.37 1.92,4.3 4.3,4.3 h 41.66 c 2.37,0 4.3,-1.92 4.3,-4.3 v 0 c 0,-2.37 -1.93,-4.3 -4.3,-4.3 z" style={{ fill: "#ffffff", }} />
                    <path className="st2" d="m 3018.82,3513.94 h -41.66 c -2.37,0 -4.3,1.92 -4.3,4.3 v 0 c 0,2.37 1.92,4.3 4.3,4.3 h 41.66 c 2.37,0 4.3,-1.92 4.3,-4.3 v 0 c 0,-2.38 -1.93,-4.3 -4.3,-4.3 z" style={{ fill: "#ffffff", }} />
                    <path className="st2" d="m 3018.82,3530.98 h -41.66 c -2.37,0 -4.3,1.92 -4.3,4.3 v 0 c 0,2.37 1.92,4.3 4.3,4.3 h 41.66 c 2.37,0 4.3,-1.92 4.3,-4.3 v 0 c 0,-2.37 -1.93,-4.3 -4.3,-4.3 z" style={{ fill: "#ffffff", }} />
                </g>
            </g>
        </SvgIcon>
    );
}