import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export function ExperimentIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g style={{ display: "inline", }} transform="translate(0 12.7)" >
                <path style={{ fill: "#2f1c63", strokeWidth: 0.44210085, }} d="m 16.990813,-1.6703846 h -5.05763 c -0.380197,0 -0.685255,0.3094706 -0.685255,0.68525611 v 0 c 0,0.38020654 0.309479,0.68525588 0.685255,0.68525588 h 5.05763 c 0.380217,0 0.685254,-0.30947021 0.685254,-0.68525588 v 0 c 0,-0.37578551 -0.305037,-0.68525611 -0.685254,-0.68525611 z" className="st1" />
                <g transform="translate(-372.778 -1318.807) scale(.4421)">
                    <path style={{ fill: "#2f1c63", }} d="m 881.66,3002.17 h -11.51 l -8.91,12.19 c -2.78,3.8 -0.06,9.15 4.65,9.15 h 31.36 z" className="st1" />
                    <rect style={{ fill: "#2f1c63", }} height={21.34} width={11.51} className="st1" y={2980.82} x={870.16} />
                    <path style={{ fill: "#9053ec", }} d="m 892.86,3002.17 h -11.51 l -8.91,12.19 c -2.78,3.8 -0.06,9.15 4.65,9.15 h 20.02 c 4.71,0 7.43,-5.35 4.65,-9.15 z" className="st2" />
                    <rect style={{ fill: "#9053ec", }} height={21.34} width={11.51} className="st2" y={2980.82} x={881.35} />
                </g>
                <path style={{ fill: "#9053ec", strokeWidth: 0.44210085, }} d="m 21.942347,-1.6703846 h -5.057634 c -0.380203,0 -0.685254,0.3094706 -0.685254,0.68525611 v 0 c 0,0.38020654 0.309469,0.68525588 0.685254,0.68525588 h 5.057634 c 0.380211,0 0.685254,-0.30947021 0.685254,-0.68525588 v 0 c 0,-0.37578551 -0.305043,-0.68525611 -0.685254,-0.68525611 z" className="st2" />
                <line style={{ fill: "none", stroke: "#9053ec", strokeWidth: 1.48492837, strokeLinecap: "round", strokeMiterlimit: 10, }} y2={-4.053} x2={19.418} y1={-8.669} x1={19.418} className="st20" />
                <line style={{ fill: "none", stroke: "#9053ec", strokeWidth: 1.48492837, strokeLinecap: "round", strokeMiterlimit: 10, }} y2={-3.823} x2={17.177} y1={-6.343} x1={14.657} className="st20" />
                <line style={{ fill: "none", stroke: "#9053ec", strokeWidth: 1.48492837, strokeLinecap: "round", strokeMiterlimit: 10, }} y2={-3.823} x2={21.695} y1={-6.343} x1={24.215} className="st20" />
                <line style={{ fill: "none", stroke: "#f4fbfe", strokeWidth: 1.48492837, strokeLinecap: "round", strokeMiterlimit: 10, }} y2={2.087} x2={20.32} y1={2.087} x1={24.935} className="st25" />
                <line style={{ fill: "none", stroke: "#f4fbfe", strokeWidth: 1.48492837, strokeLinecap: "round", strokeMiterlimit: 10, }} y2={5.549} x2={20.32} y1={5.549} x1={24.935} className="st25" />
            </g>
        </SvgIcon>
    );
}