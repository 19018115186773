import { createSlice, PayloadAction } from '@reduxjs/toolkit'; //eslint-disable-line sort-imports
import { RootState } from '../../app/store';
import { Robot, Session } from '../../types/base';

export interface RobotsState {
  robots: Robot[];
  sessions: Session[];
  selectedRobot: Robot | null;
  selectedSession: Session | null;
  status: 'idle' | 'loading' | 'error';
  error: string | null;
}

const initialState: RobotsState = {
  robots: [],
  sessions: [],
  selectedRobot: null,
  selectedSession: null,
  status: 'idle',
  error: null,
};

export const robotsSlice = createSlice({
  name: 'robots',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setStatus: (state, action: PayloadAction<{ status: 'idle' | 'loading' | 'error'; error: undefined | string }>) => {
      state.status = action.payload.status;
      state.error = action.payload.error;
    },
    setRobots: (state, action: PayloadAction<Robot[]>) => {
      state.robots = action.payload;
      state.sessions = [];
      state.selectedRobot = null;
      state.selectedSession = null;      
    },
    setSessions: (state, action: PayloadAction<Session[]>) => {
      state.sessions = action.payload;
    },
    setSelectedRobot: (state, action: PayloadAction<Robot|null>) => {
      state.selectedRobot = action.payload;
      // If the robot is selected, clear the sessions
      state.sessions = [];
      state.selectedSession = null;
    },
    setSelectedSession: (state, action: PayloadAction<Session|null>) => {
      state.selectedSession = action.payload;
    },
  },
});

export const { setStatus, setRobots, setSessions, setSelectedRobot, setSelectedSession } = robotsSlice.actions;

//Selectors
export const selectRobots = (state: RootState) => state.robotsView;

export default robotsSlice.reducer;
