import { call, put } from 'redux-saga/effects';
import { setRobots, setStatus } from '../../components/robots/RobotsSlice';
import { addRobotApi, getRobotsApi } from '../../services/mapper/robots-api';
import * as actions from '../actions';

export function* addRobot(action: ReturnType<typeof actions.addRobot>) {
  try {
    yield put(setStatus({ status: 'loading', error: undefined }));

    const newRobotId = yield call(
      addRobotApi,
      action.payload.apolloClient,
      action.payload.user.id,
      action.payload.robotRequest.label,
      action.payload.robotRequest.metadata,
    );

    yield put(setStatus({ status: 'idle', error: undefined }));

    // Go get the robots to get the updated list.
    yield put(actions.getRobots({ apolloClient: action.payload.apolloClient, user: action.payload.user }));
  } catch (e) {
    console.log(e);
    yield put(setStatus({ status: 'error', error: String(e) }));
    return;
  }
}

/**
 * Get all robots for the provided user.
 *
 * @param {ReturnType<typeof actions.login>} action
 * @return {*}
 */
export function* getRobots(action: ReturnType<typeof actions.getRobots>) {
  try {
    yield put(setStatus({ status: 'loading', error: undefined }));

    console.log(action.payload.user);

    const robots = yield call(getRobotsApi, action.payload.apolloClient, action.payload.user.label);

    // Set the robots in the slice.
    yield put(setRobots(robots));

    yield put(setStatus({ status: 'idle', error: undefined }));
  } catch (e) {
    console.log(e);
    yield put(setStatus({ status: 'error', error: String(e) }));
    return;
  }
}

export function* updateRobot(action: ReturnType<typeof actions.updateRobot>) {}

export function* deleteRobot(action: ReturnType<typeof actions.deleteRobot>) {}
