import { gql } from '@apollo/client';

import { NavAbilityClient } from 'navabilitysdk';
import { Token } from '../../types/auth';
import { User } from '../../types/mapper/maps';
import { GQL_GET_SELF, GQL_GET_USER } from './graphql/Users';

/**
 * Get the current User based on their sub.
 *
 * @export
 * @param {NavAbilityClient} navAbilityClient
 * @param {string} sub The sub of the user.
 * @return {*}  {Promise<User>}
 */
export async function getSelfApi(navAbilityClient: NavAbilityClient, token?: Token): Promise<User> {
  const response = token ? await navAbilityClient.mutate({
    mutation: gql(`${GQL_GET_SELF}`),
    fetchPolicy: 'network-only',
    variables: {
      TOKEN_SUB: token.sub,
    },
  }) :
  // Get the guest user 
  await await navAbilityClient.query({
    query: gql(`${GQL_GET_USER}`),
    fetchPolicy: 'network-only',
    variables: {
      LABEL: 'guest@navability.io',
    },
  });

  if (response.data.errors) {
    throw Error(`Error: ${response.data.errors[0]}`);
  } else if (response.data.users.length !== 1)
    throw Error(`A user was not returned.`);
  else {
    // Get the first user otherwise undefined.
    return response.data.users[0];
  }
}

/**
 * Get a User based on their email (LABEL), undefined if there is none.
 *
 * @export
 * @param {NavAbilityClient} navAbilityClient
 * @param {string} sub The sub of the user.
 * @return {*}  {Promise<User>}
 */
export async function getUserApi(navAbilityClient: NavAbilityClient, label: string): Promise<User> {
  const response = await navAbilityClient.query({
    query: gql(`${GQL_GET_USER}`),
    fetchPolicy: 'network-only',
    variables: {
      LABEL: label,
    },
  });
  if (response.data.errors) {
    throw Error(`Error: ${response.data.errors[0]}`);
  } else {
    // Get the first user otherwise undefined.
    return response.data.mapUsers.length > 0 ? response.data.mapUsers[0] : undefined;
  }
}
