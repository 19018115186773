import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export function DeleteIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g style={{ display: "inline", }} transform="translate(0 12.7)" >
                <g style={{ display: "inline", }} transform="translate(-2046.024 -842.8) scale(.5962)" >
                    <circle className="st1" cx={3455.68} cy={1420.72} r={21.34} style={{ fill: "#2f1c63", }} />
                    <rect x={3455.68} y={1399.37} className="st1" width={8.96} height={42.69} style={{ fill: "#2f1c63", }} />
                    <circle className="st2" cx={3464.64} cy={1420.72} r={21.34} style={{ fill: "#9053ec", }} />
                    <path className="st1" d="m 3475.71,1427.17 -6.45,-6.45 6.45,-6.45 c 1.28,-1.28 1.28,-3.35 0,-4.62 -1.28,-1.28 -3.35,-1.28 -4.62,0 l -6.45,6.45 v 0 l -6.45,-6.45 c -1.28,-1.28 -3.35,-1.28 -4.63,0 -1.28,1.28 -1.28,3.35 0,4.62 l 6.45,6.45 -6.45,6.45 c -1.28,1.28 -1.28,3.35 0,4.62 v 0 c 1.28,1.28 3.35,1.28 4.62,0 l 6.45,-6.45 v 0 l 6.45,6.45 c 1.28,1.28 3.35,1.28 4.62,0 1.29,-1.27 1.29,-3.35 0.01,-4.62 z" style={{ fill: "#2f1c63", }} />
                </g>
            </g>
        </SvgIcon>
    );
}

export function DarkNegativeDeleteIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g style={{ display: "inline", }} transform="translate(0 12.7)" >
                <g style={{ display: "inline", }} transform="translate(-2046.024 -842.8) scale(.5962)" >
                    <circle className="st1" cx={3455.68} cy={1420.72} r={21.34} style={{ fill: "#2f1c63", }} />
                    <rect x={3455.68} y={1399.37} className="st1" width={8.96} height={42.69} style={{ fill: "#2f1c63", }} />
                    <circle className="st2" cx={3464.64} cy={1420.72} r={21.34} style={{ fill: "#ffffff", }} />
                    <path className="st1" d="m 3475.71,1427.17 -6.45,-6.45 6.45,-6.45 c 1.28,-1.28 1.28,-3.35 0,-4.62 -1.28,-1.28 -3.35,-1.28 -4.62,0 l -6.45,6.45 v 0 l -6.45,-6.45 c -1.28,-1.28 -3.35,-1.28 -4.63,0 -1.28,1.28 -1.28,3.35 0,4.62 l 6.45,6.45 -6.45,6.45 c -1.28,1.28 -1.28,3.35 0,4.62 v 0 c 1.28,1.28 3.35,1.28 4.62,0 l 6.45,-6.45 v 0 l 6.45,6.45 c 1.28,1.28 3.35,1.28 4.62,0 1.29,-1.27 1.29,-3.35 0.01,-4.62 z" style={{ fill: "#2f1c63", }} />
                </g>
            </g>
        </SvgIcon>
    )
}