import { useApolloClient } from '@apollo/client';
import {
  AppBar,
  Box,
  ClickAwayListener,
  CssBaseline,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery
} from '@mui/material';
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { SnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NegativeFilterIcon, NegativeMenuIcon, NegativeUserIcon } from '../../components/icons/icons';
import NavAbilityUserProvider from '../state/NavAbilityUserProvider';
import { selectUser, UserState } from '../state/user-slice';
import AccountContent from './account-content';
import FilterContent from './filter-content';
import MenuContent from './MenuContent';
import theme from './theme';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const Layout = ({ children }: { children: any }) => {
  const isLargeMedia = useMediaQuery('(min-width:600px)');

  const siteMetadata: any = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  const title: string = siteMetadata.site.siteMetadata.title;

  const globalState = useApolloClient(); // Make sure it's a browser and not Gatsby building.
  const [state, setState] = useState({
    isMenuDrawerOpen: false,
    isAccountDrawerOpen: false,
    isFilterDrawerOpen: false,
  });
  const userState: UserState = useSelector(selectUser);

  useEffect(() => {
  });

  const openMenuDrawer = () => {
    setState({ ...state, isMenuDrawerOpen: true });
  };

  const closeMenuDrawer = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab' || event.key === 'Shift') {
      return;
    }

    setState({ ...state, isMenuDrawerOpen: false });
  };

  const handleMenuClickAway = (event: any) => {
    setState({ ...state, isMenuDrawerOpen: false });
  };

  const openAccountDrawer = (event: any) => {
    setState({ ...state, isAccountDrawerOpen: true });
  };

  const handleAccountClickAway = (event: any) => {
    setState({ ...state, isAccountDrawerOpen: false });
  };

  const openFilterDrawer = (event: any) => {
    setState({ ...state, isFilterDrawerOpen: true });
  };
  const handleFilterClickAway = (event: any) => {
    setState({ ...state, isFilterDrawerOpen: false });
  };

  return (
    <CssBaseline>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <NavAbilityUserProvider>
            <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
              <AppBar color="primary">
                <Toolbar variant="dense">
                  <IconButton edge="start" color="inherit" aria-label="menu" onClick={openMenuDrawer} size="large">
                    <NegativeMenuIcon fontSize="large" />
                  </IconButton>
                  {isLargeMedia && (
                    <Typography
                      variant="h3"
                      sx={{
                        textDecoration: 'none',
                        margin: 0,
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                      }}
                    >
                      <Link to="/home" style={{ color: 'background', textDecoration: 'none' }}>
                        {title}
                      </Link>
                    </Typography>
                  )}
                  <Typography variant="h4" color="inherit" sx={{ marginLeft: 'auto' }}>
                    {userState.user.label}
                  </Typography>
                  <IconButton
                    id="filterToggle"
                    edge="end"
                    color="inherit"
                    aria-label="filter"
                    onClick={openAccountDrawer}
                    size="large"
                  >
                    <NegativeUserIcon fontSize="large" />
                  </IconButton>
                  <IconButton
                    id="filterToggle"
                    edge="end"
                    color="inherit"
                    aria-label="filter"
                    onClick={openFilterDrawer}
                    size="large"
                  >
                    <NegativeFilterIcon fontSize="large" />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <Toolbar />

              <Drawer anchor="left" open={state.isMenuDrawerOpen} onClose={closeMenuDrawer}>
                <ClickAwayListener onClickAway={handleMenuClickAway}>
                  <div onKeyDown={closeMenuDrawer}>
                    <MenuContent title={isLargeMedia ? undefined : title} onMenuItemClicked={closeMenuDrawer} />
                  </div>
                </ClickAwayListener>
              </Drawer>

              <Drawer anchor="right" open={state.isAccountDrawerOpen}>
                <ClickAwayListener onClickAway={handleAccountClickAway}>
                  <div>
                    <AccountContent />
                  </div>
                </ClickAwayListener>
              </Drawer>

              <Drawer anchor="right" open={state.isFilterDrawerOpen}>
                <ClickAwayListener onClickAway={handleFilterClickAway}>
                  <div>
                    <FilterContent />
                  </div>
                </ClickAwayListener>
              </Drawer>

              <Box
                sx={{
                  height: '92vh',
                  margin: `0 auto`,
                  maxWidth: '100%',
                  flexDirection: 'column',
                  padding: `0 1.0875rem 1.45rem`,
                  overflowY: 'scroll',
                  display: 'flex',
                }}
              >
                <Box>{children}</Box>
              </Box>

              <footer></footer>
            </SnackbarProvider>
          </NavAbilityUserProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </CssBaseline>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
