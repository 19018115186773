import { gql } from '@apollo/client';

import { NavAbilityClient } from 'navabilitysdk';
import { User } from '../../types/base';
import { Map } from '../../types/mapper/maps';
import { Workflow } from '../../types/mapper/workflows';
import { GQL_ADD_WORKFLOW, GQL_DELETE_WORKFLOW, GQL_GET_WORKFLOWS_FOR_USER } from './graphql/Workflows';

/**
 * Get maps for a user.
 *
 * @export
 * @param {NavAbilityClient} navAbilityClient
 * @param {string} sub The sub of the user.
 * @return {*}  {Promise<Workflow>}
 */
export async function getWorkflowsApi(navAbilityClient: NavAbilityClient, user: User): Promise<Workflow[]> {
  const response = await navAbilityClient.query({
    query: gql(`${GQL_GET_WORKFLOWS_FOR_USER}`),
    fetchPolicy: 'network-only',
    variables: {
      USER_ID: user.id,
    },
  });
  if (response.data.errors) {
    throw Error(`Error: ${response.data.errors[0]}`);
  } else {
    // Deserialize the data
    const maps = response.data.workflows.map((w) => {
      
      let parsedData = {};
      try {
        parsedData = JSON.parse(atob(w.data));
      } catch(Exception) {
        console.log(`Unable to deserialize workflow data: ${w.data}`)
      }
      return {
        ...w,
        data: parsedData,
      };
    });
    return maps;
  }
}

/**
 * Create a map for a user and return the new ID.
 *
 * @export
 * @param {NavAbilityClient} navAbilityClient
 * @param {string} sub The sub of the user.
 * @return {*}  {Promise<Workflow>}
 */
export async function addWorkflowApi(
  navAbilityClient: NavAbilityClient,
  user: User,
  map: Map,
  workflow: Workflow,
): Promise<string> {
  console.warn('Fix this and refactor here.');
  const encodedWorkflow = {
    label: workflow.label,
    description: workflow.description,
    status: workflow.status,
    _version: '0.20.0',
    data: btoa(JSON.stringify(workflow)),
    result: undefined,
    map: {
      connect: {
        where: { node: { id: map.id } },
      },
    },
    _type: workflow._type,
    createdBy: {
      connect: { where: { node: { id: user.id } } },
    },
  };

  console.log(encodedWorkflow);

  const response = await navAbilityClient.mutate({
    mutation: gql(`${GQL_ADD_WORKFLOW}`),
    fetchPolicy: 'network-only',
    variables: {
      USER_ID: user.id,
      MAP_ID: map.id,
      WORKFLOW: encodedWorkflow,
    },
  });
  if (response.data.errors) {
    throw Error(`Error: ${response.data.errors[0]}`);
  } else {
    console.log(response.data);
    // Return the workflow ID.
    const map = response.data.addWorkflows.workflows[0];
    return map.id;
  }
}

/**
 * Delete a map, returns nothing.
 *
 * @export
 * @param {NavAbilityClient} navAbilityClient
 * @param {string} user The user (not used at present).
 * @param {string} mapId The ID of the map.
 * @return {*}  {Promise<Workflow>}
 */
export async function deleteWorkflowApi(navAbilityClient: NavAbilityClient, user: User, workflowId: string) {
  const response = await navAbilityClient.mutate({
    mutation: gql(`${GQL_DELETE_WORKFLOW}`),
    fetchPolicy: 'network-only',
    variables: {
      WORKFLOW_ID: workflowId,
    },
  });
  if (response.data.errors) {
    throw Error(`Error: ${response.data.errors[0]}`);
  } else {
    console.log(response.data);
    // Return nothing.
    return null;
  }
}
