import { takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { getRobotsLatest } from './sagas/home';
import { addMap, deleteMap, getMaps } from './sagas/maps';
import { addRobot, deleteRobot, getRobots, updateRobot } from './sagas/robots';
import { getSessions } from './sagas/sessions';
import { getSelf, getUser } from './sagas/users';
import { addWorkflow, deleteWorkflow, getWorkflows } from './sagas/workflows';

export function* rootSaga() {
  // Users
  yield takeLatest(actions.getSelf.type, getSelf);
  yield takeLatest(actions.getUser.type, getUser);

  // Home
  yield takeLatest(actions.getRobotsLatest.type, getRobotsLatest);

  // Robots
  yield takeLatest(actions.addRobot.type, addRobot);
  yield takeLatest(actions.getRobots.type, getRobots);
  yield takeLatest(actions.updateRobot.type, updateRobot);
  yield takeLatest(actions.deleteRobot.type, deleteRobot);

  // Sessions
  yield takeLatest(actions.getSessions.type, getSessions);

  // Maps
  yield takeLatest(actions.addMap.type, addMap);
  yield takeLatest(actions.getMaps.type, getMaps);
  yield takeLatest(actions.deleteMap.type, deleteMap);

  // Workflows
  yield takeLatest(actions.addWorkflow.type, addWorkflow);
  yield takeLatest(actions.getWorkflows.type, getWorkflows);
  yield takeLatest(actions.deleteWorkflow.type, deleteWorkflow);
}
