import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export function UserIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g style={{ display: "inline", }} transform="translate(0 12.7)" >
                <g style={{ display: "inline", }} transform="translate(-278.295 -564.1) scale(.4421)" >
                    <path className="st1" d="M 679.16,1308.13 H 644.5 v -7.57 c 0,-6.78 3.9,-12.65 9.57,-15.49 -2.84,-2.28 -4.67,-5.78 -4.67,-9.71 0,-6.87 5.57,-12.43 12.43,-12.43 h 11.91 l 5.42,45.2" style={{ fill: "#2f1c63", }} />
                    <path className="st2" d="m 681.5,1285.07 c 2.85,-2.28 4.67,-5.78 4.67,-9.71 0,-6.87 -5.57,-12.43 -12.43,-12.43 -6.87,0 -12.43,5.57 -12.43,12.43 0,3.93 1.82,7.43 4.67,9.71 -5.67,2.85 -9.57,8.71 -9.57,15.49 v 7.57 h 34.66 v -7.57 c -0.01,-6.78 -3.9,-12.64 -9.57,-15.49 z" style={{ fill: "#9053ec", }} />
                </g>
            </g>
        </SvgIcon>
    )
}

export function NegativeUserIcon(props: any) {
    return (
        <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
            <g style={{ display: "inline", }} transform="translate(0 12.7)" >
                <g style={{ display: "inline", }} transform="translate(-278.295 -564.1) scale(.4421)" >
                    <path className="st1" d="M 679.16,1308.13 H 644.5 v -7.57 c 0,-6.78 3.9,-12.65 9.57,-15.49 -2.84,-2.28 -4.67,-5.78 -4.67,-9.71 0,-6.87 5.57,-12.43 12.43,-12.43 h 11.91 l 5.42,45.2" style={{ fill: "#9053ec", }} />
                    <path className="st2" d="m 681.5,1285.07 c 2.85,-2.28 4.67,-5.78 4.67,-9.71 0,-6.87 -5.57,-12.43 -12.43,-12.43 -6.87,0 -12.43,5.57 -12.43,12.43 0,3.93 1.82,7.43 4.67,9.71 -5.67,2.85 -9.57,8.71 -9.57,15.49 v 7.57 h 34.66 v -7.57 c -0.01,-6.78 -3.9,-12.64 -9.57,-15.49 z" style={{ fill: "#ffffff", }} />
                </g>
            </g>
        </SvgIcon>
    )
}
