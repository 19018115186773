import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { Workflow } from '../../types/mapper/workflows'

export interface WorkflowState {
  workflows: Workflow[];
  status: 'loading' | 'idle' | 'error';
  error: string | undefined;
  // selectedMap: NavAbilityMap | undefined;
}

const initialState: WorkflowState = {
  workflows: [],
  status: 'idle',
  error: undefined,
  // selectedMap: undefined,
}

export const workflowsSlice = createSlice({
  name: 'workflows',
  initialState,
  reducers: {
    setStatus: (state: WorkflowState, action: PayloadAction<{status: 'loading' | 'idle' | 'error', error: string | undefined}>) => {
      state.status = action.payload.status;
      state.error = action.payload.error;
    },
    setWorkflows: (state: WorkflowState, action: PayloadAction<NavAbilityMap[]>) => {
      state.workflows = action.payload;
    },
    // setSelectedMap: (state: WorkflowState, action: PayloadAction<NavAbilityMap|undefined>) => {
    //   state.selectedMap = action.payload;
    // },
  },
})

// Action creators are generated for each case reducer function
export const { setStatus, setWorkflows} = workflowsSlice.actions

// Selectors
export const selectWorkflows = (state: RootState) => state.workflows;

export default workflowsSlice.reducer