export const GQL_GET_MAPS = `
query sdk_get_maps($USER_ID: ID!) {
  maps (where: {usersConnection_SOME: {node: {id: $USER_ID }}}) 	{
    id
    label
    description
    status
    createdTimestamp
    lastUpdatedTimestamp
    thumbnailId
    usersConnection (where: {node: {id: $USER_ID}}) {
      edges {
        role
        node {
          id
          label
        }
      }
    }
    visualization {
      hierarchyId
      octreeId
      metadataId
    }
    annotations {
      id
      text
      position
    }
    affordances {
      id
      label
      position
      rotation
      scale
    }
#    sessions {
#      id
#      label
#      robotLabel
#      userLabel
#    }
    workflows {
      id
      label
      description
      status
      data
      result
      _type
      _version
      
      createdTimestamp
      lastUpdatedTimestamp
    }
  }
}`

export const GQL_ADD_MAP = `
mutation ($USER_ID: ID!, $LABEL: String!, $DESCRIPTION: String!, $DATA: B64JSON!) {
  addMaps(
    input: [{
    	label: $LABEL,
      description: $DESCRIPTION,
      status: "draft",
      data: $DATA,
      users: {
        connect: {
          where:{
            node: {
              id: $USER_ID
            }
          },
          edge: {
            role: "admin"
          }
        }
      }
    }]) {
    maps {
      id
    }
  }
}`

export const GQL_DELETE_MAP = `
mutation ($MAP_ID: ID!) {
  deleteMaps(
    where: {id: $MAP_ID}
    ) {
    nodesDeleted 
    bookmark
  }
}`

export const GQL_ATTACH_USER_TO_MAP = `
mutation ($MAP_ID: ID!, $USER_ID: ID!, $ROLE: String!){
  updateMaps(
    where: { id: $MAP_ID }
    connect: {
      users: {
        where: { node: { id: $USER_ID } }
        edge: { role: $ROLE }
      }
    }
  ) {
    info {
      nodesCreated
    }
  }
}
`